// OngoingRaces.js
import React, { useState, useEffect } from 'react';
import '../styles/ongoingRaces.css';
import { useQuery, useQueryClient } from 'react-query';
import { fetchOngoingRaces, fetchMaps } from '../utils/api';
import { useAuth } from '../contexts/AuthContext';
import { formatTime } from '../utils/formatTime';
import { TailSpin } from 'react-loader-spinner';

const OngoingRaces = ({ showOngoingRaces, setShowOngoingRaces }) => {
  const { userAccount } = useAuth();
  const queryClient = useQueryClient();
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedRaces, setExpandedRaces] = useState({});

  const fetchMapData = async () => {
    const mapsData = await fetchMaps();
    return mapsData.reduce((acc, map) => ({ ...acc, [map._id]: map }), {});
  };

  const { data, isLoading } = useQuery(
    ['ongoingRaces', userAccount, currentPage],
    () => fetchOngoingRaces(userAccount, currentPage, itemsPerPage),
    {
      enabled: !!userAccount && showOngoingRaces,
      keepPreviousData: true,
      staleTime: 0,
      onError: (error) => console.error('Error fetching ongoing races:', error),
    }
  );

  const { data: maps } = useQuery(
    ['maps', userAccount],
    fetchMapData,
    { enabled: !!userAccount && showOngoingRaces, staleTime: 0 }
  );

  const ongoingRaces = data?.races || [];
  const totalPages = data?.totalPages || 1;

  useEffect(() => {
    const timerInterval = setInterval(() => {
      queryClient.setQueryData(['ongoingRaces', userAccount, currentPage], (oldData) => {
        if (!oldData) return oldData;
        const updatedRaces = oldData.races.map((race) => ({
          ...race,
          remainingTime: Math.max(
            0,
            race.duration - Math.floor((new Date() - new Date(race.startTime)) / 1000)
          ),
        }));
        return { ...oldData, races: updatedRaces };
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [userAccount, currentPage, queryClient]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    queryClient.prefetchQuery(['ongoingRaces', userAccount, page], () =>
      fetchOngoingRaces(userAccount, page, itemsPerPage)
    );
  };

  const toggleExpand = (raceIndex) => {
    setExpandedRaces((prev) => ({
      ...prev,
      [raceIndex]: !prev[raceIndex],
    }));
  };

  return (
    <>
      <div className="ongoing-races-overlay" onClick={() => setShowOngoingRaces(false)}></div>
      <div className="ongoing-races-modal show">
        <button className="close-button-ongoing" onClick={() => setShowOngoingRaces(false)}>✖</button>
        <div className="ongoing-races-header">
          <h2>ONGOING RACES</h2>
        </div>
        <div className="ongoing-races-list">
          {isLoading ? (
            <div className="ongoing-races-loading-container">
              <TailSpin height="100" width="100" color="#00ff00" ariaLabel="loading" />
            </div>
          ) : ongoingRaces.length > 0 ? (
            ongoingRaces.map((race, raceIndex) => {
              const mapDetails = maps?.[race.mapId] || {};
              const isExpanded = expandedRaces[raceIndex];

              return (
                <div key={raceIndex} className="ongoing-race-item">
                  <div className="ongoing-race-columns">
                    <div className="ongoing-race-column ongoing-race-map-column">
                      <img
                        src={mapDetails.image || 'path/to/default/image.png'}
                        alt="Map"
                        className="ongoing-race-map-image"
                      />
                      <div className="ongoing-race-map-name">
                        {mapDetails.name || 'Unknown Map'}
                      </div>
                    </div>

                    <div className="ongoing-race-column ongoing-race-details-column">
                      <div className="ongoing-race-car-items">
                        <img
                          src={race.carImageUrl || 'path/to/default/car-image.png'}
                          alt="Car"
                          className="ongoing-race-car-image"
                        />
                        <div className="ongoing-race-items">
                          {race.equippedItems &&
                            race.equippedItems.map((item, idx) => (
                              <img
                                key={idx}
                                src={item.img ? `https://ipfs.neftyblocks.io/ipfs/${item.img}` : 'path/to/default/item-image.png'}
                                alt={item.templateId}
                                className="ongoing-race-item-image"
                              />
                            ))}
                        </div>
                      </div>
                      <div className="ongoing-race-car-name">
                        Car: {race.carNftDetails?.name || race.carId}
                      </div>
                      <div className="ongoing-race-time">
                        Time remaining: {formatTime(race.remainingTime)}
                      </div>
                      <button className="toggle-button" onClick={() => toggleExpand(raceIndex)}>
                        {isExpanded ? '▲ Hide Details' : '▼ Show Details'}
                      </button>
                      {isExpanded && (
                        <div className="race-additional-details">
                          <h4>Race Details</h4>
                          <div className="time-info">
                            <span><strong>Start:</strong> {new Date(race.startTime).toLocaleString()}</span>
                            <span><strong>End:</strong> {new Date(race.endTime).toLocaleString()}</span>
                          </div>

                          <div className="detail-section">
                            <h5>Car</h5>
                            <ul>
                              <li><strong>ID:</strong> {race.carId}</li>
                              <li>Name: {race.carNftDetails?.name}</li>
                              <li>Speed: {race.carNftDetails?.SpeedLevel}</li>
                              <li>Acceleration: {race.carNftDetails?.AccelerationLevel}</li>
                              <li>Nitro: {race.carNftDetails?.NitroLevel}</li>
                              <li>Luck: {race.carNftDetails?.LuckLevel}</li>
                              <li>Rarity: {race.carNftDetails?.CarRarity}</li>
                              <li>Skin: {race.carNftDetails?.SkinRarity}</li>
                            </ul>
                          </div>

                          <div className="detail-section">
                            <h5>Map Details</h5>
                            <ul>
                              <li>Racers: {race.mapDetails?.bots}</li>
                              <li>Total Coins: {race.mapDetails?.totalCoins?.$numberDecimal || 'N/A'}</li>
                            </ul>
                          </div>

                          <div className="detail-section">
                            <h5>Map Boosts</h5>
                            <ul>
                              <li>Win: {race.boosts?.boostWin}</li>
                              <li>Drop: {race.boosts?.boostDrop}</li>
                              <li>Exp: {race.boosts?.boostExp}</li>
                            </ul>
                          </div>

                          <div className="detail-section">
                            <h5>Equipped Items</h5>
                            <ul>
                              {race.equippedItems?.map((item, i) => (
                                <li key={i}>
                                  <strong>ID:</strong> {item.asset_id} | 
                                  <strong> Win:</strong> {item.victoryChance} | 
                                  <strong> Exp:</strong> {item.xpBonus} | 
                                  <strong> Drop:</strong> {item.dropChance}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="no-ongoing-races">No ongoing races</div>
          )}
        </div>

        <div className="ongoing-races-pagination">
          <button
            onClick={() => { if (currentPage > 1) handlePageChange(currentPage - 1); }}
            disabled={currentPage === 1}
            aria-label="Previous Page"
          >
            Previous
          </button>
          <span>Page {currentPage} of {totalPages}</span>
          <button
            onClick={() => { if (currentPage < totalPages) handlePageChange(currentPage + 1); }}
            disabled={currentPage === totalPages}
            aria-label="Next Page"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default OngoingRaces;
