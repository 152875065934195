const config = {
  apiBaseUrl: 'https://pixelraces.com',
  websocketUrl: 'wss://pixelraces.com',

  //apiBaseUrl: 'http://localhost:5000',
  //websocketUrl: 'ws://localhost:5000/socket.io',
  
  //apiBaseUrl: 'https://pixelraces.com',
  //websocketUrl: 'wss://pixelraces.com',
};

export default config;

  //apiBaseUrl: 'http://localhost:5000',
  //websocketUrl: 'ws://localhost:5000/socket.io',