import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { renewAuthToken, fetchCsrfTokenAgain } from '../utils/api' 
// NOTE: "fetchCsrfTokenAgain" is the new function we created in api.js
// If you prefer, you can do it inline in Header

import Cookies from 'js-cookie'
import config from '../config'
import endpoints from '../utils/endpoints'
import logo from '../assets/logo.png'
import './Header.css'
import { toast } from 'react-toastify'
import {jwtDecode} from 'jwt-decode' // Mantive a importação conforme solicitado

const Header = () => {
  const {
    userAccount,
    isAdmin,
    login,
    logout,
    setRacingCoins,
    racingCoins,
    sessionKit,
    loading
  } = useAuth()

  const [csrfToken, setCsrfToken] = useState('')
  const [menuOpen, setMenuOpen] = useState(false)
  const navigate = useNavigate()

  // 1) Get CSRF token on mount
  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await fetch(`${config.apiBaseUrl}/api/auth/csrf-token`, {
          method: 'GET',
          credentials: 'include'
        })
        if (response.ok) {
          const data = await response.json()
          setCsrfToken(data.csrfToken)
          Cookies.set('csrfToken', data.csrfToken, { expires: 1 })
        }
      } catch (error) {
        console.error('Error fetching CSRF token:', error)
      }
    }
    fetchCsrfToken()
  }, [])

  // 2) Renew JWT token if about to expire
  useEffect(() => {
    const interval = setInterval(async () => {
      const token = Cookies.get('authToken')
      if (token) {
        try {
          console.log('[Header] Checking JWT token')
          const decodedToken = jwtDecode(token)
          const expiryTime = decodedToken.exp * 1000
          const currentTime = Date.now()
          
          // Renew if less than 10 minutes until expiration
          if (expiryTime - currentTime < 10 * 60 * 1000) {
            console.log('[Header] Token close to expiring, renewing...')
            const newToken = await renewAuthToken()
            if (newToken) {
              console.log('[Header] Token renewed successfully')
              Cookies.set('authToken', newToken, { expires: 7 })
              const decoded = jwtDecode(newToken)
              login(decoded.wallet, decoded.isAdmin, racingCoins)
            } else {
              console.log('[Header] Failed to renew token, logging out')
              logout()
            }
          } else {
            console.log('[Header] Token still valid for more than 10 minutes')
          }
        } catch (error) {
          console.error('[Header] Error during token renewal:', error)
          logout()
        }
      }
    }, 5 * 60 * 1000) // Check every 5 minutes
    
    return () => clearInterval(interval)
  }, [logout, login, racingCoins])

  // 3) Manual login: WharfKit + backend login
  const handleLogin = async () => {
    if (!sessionKit) {
      toast.error('WharfKit not initialized. Please reload the page.');
      return;
    }
    if (!csrfToken && !Cookies.get('csrfToken')) {
      toast.error('CSRF Token not found. Please reload the page.');
      return;
    }

    try {
      console.log('[Header] Starting WharfKit login');
      const loginResult = await sessionKit.login();

      // Get session
      const newSession = loginResult.session || loginResult;
      if (!newSession.chain && !newSession.client) {
        console.log('Chain information not found in newSession =>', newSession);
      }

      const actor = newSession.permissionLevel.actor.toString();
      console.log('[Header] WharfKit login successful => actor:', actor);

      // Backend login
      console.log('[Header] Starting backend login');
      const response = await fetch(`${config.apiBaseUrl}/api/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken || Cookies.get('csrfToken')
        },
        body: JSON.stringify({ walletAddress: actor }),
        credentials: 'include'
      });

      if (response.ok) {
        const data = await response.json();
        
        // Store token
        Cookies.set('authToken', data.token, { 
          expires: 7,
          secure: window.location.protocol === 'https:',
          sameSite: 'strict'
        });
        
        setRacingCoins(data.racingCoins);
        const decoded = jwtDecode(data.token);

        // Register in AuthContext
        login(decoded.wallet, decoded.isAdmin, data.racingCoins, newSession);

        // Get new CSRF token
        console.log('[Header] Updating CSRF token');
        const updated = await fetchCsrfTokenAgain();
        if (!updated) {
          console.warn('[Header] Could not update CSRF token after login');
        }

        toast.success('Login successful!');
      } else {
        console.error('[Header] Backend login failed:', await response.text());
        toast.error('Failed to login to backend.');
      }
    } catch (error) {
      console.error('[Header] Error during login process:', error);
      toast.error(error.message || 'Error during login');
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('[Header] Error during logout:', error);
      toast.error('Error during logout. Please try again.');
    }
  };

  const handlePlayNow = () => {
    navigate('/homegame')
  }

  const renderFallingPixels = () => {
    const pixels = []
    const totalPixels = 10
    for (let i = 0; i < totalPixels; i++) {
      const style = {
        left: `${(i / totalPixels) * 110}%`,
        animationDuration: `${2 + Math.random() * 3}s`
      }
      pixels.push(<div key={i} className="pixel" style={style}></div>)
    }
    return pixels
  }

  if (loading) {
    return <div style={{ color: '#fff' }}>Loading...</div>
  }

  return (
    <header className="menu">
      <div className="logo">
        <img src={logo} alt="Pixel Races Logo" className="logo-image" />
      </div>
      <button className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
        &#9776;
      </button>
      <nav className={`nav-center ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li><button onClick={() => navigate('/')}>Home</button></li>
          <li><button onClick={() => navigate('/drops')}>Drops</button></li>
          <li><button onClick={() => navigate('/stake')}>Stake</button></li>
          <li><button onClick={() => navigate('/about')}>About</button></li>
          {userAccount && (
            <>
              <li>
                <button onClick={() => navigate('/report')} className="report-button">
                  Report
                </button>
              </li>
              <li>
                <button className="play-now-button" onClick={handlePlayNow}>
                  <i className="fas fa-gamepad"></i>
                  <span>Play</span>
                </button>
              </li>
              <li>
                <button className="logout-button" onClick={handleLogout}>
                  <i className="fas fa-sign-out-alt"></i>
                  <span>Logout</span>
                </button>
              </li>
            </>
          )}
          {!userAccount && (
            <li>
              <button className="login-button" onClick={handleLogin}>
                <i className="fas fa-sign-in-alt"></i>
                <span>Login</span>
              </button>
            </li>
          )}
        </ul>
      </nav>
      {userAccount && (
        <div className="user-info-container">
          <p className="user-info">{userAccount}</p>
        </div>
      )}
      {renderFallingPixels()}
    </header>
  )
}

export default Header
