// src/components/StoreModal.js

import React, { useState, useEffect } from 'react'
import '../styles/StoreModal.css'
import { useAuth } from '../contexts/AuthContext'
import { getStoreConfig, fetchUser, buyRaceLimit } from '../utils/api'

const StoreModal = ({
  show,
  onClose,
  setNotificationMessage,
  setShowNotification
}) => {
  const { userAccount, session, loginWharfKit } = useAuth()

  const [status, setStatus] = useState('')
  const [error, setError] = useState('')
  
  const [storeConfig, setStoreConfig] = useState(null)
  const [userBonus, setUserBonus] = useState(0)
  const [wantedLimits, setWantedLimits] = useState(1)
  const [maxCanBuy, setMaxCanBuy] = useState(0)

  useEffect(() => {
    if (!show || !userAccount) {
      return
    }

    const loadData = async () => {
      try {
        setError('')
        setStatus('Loading store data...')

        const config = await getStoreConfig()
        const userData = await fetchUser(userAccount)

        if (!config || config.maxBonusPerDay === undefined) {
          setStoreConfig(null)
          setError('Store settings not found. Please ask an admin to set them.')
          setStatus('')
          return
        }

        const currentBonus = userData?.bonusRaceLimit || 0
        const canStillBuy = config.maxBonusPerDay - currentBonus

        setStoreConfig({
          maxPerDay: config.maxBonusPerDay,
          baseCost: config.baseCost,
          costIncrement: config.costIncrement
        })

        setUserBonus(currentBonus)
        setMaxCanBuy(canStillBuy)
        setWantedLimits(canStillBuy > 0 ? 1 : 0)
        setStatus('')
      } catch (err) {
        setError('Failed to load store data. Please reload the page.')
        setStatus('')
      }
    }

    loadData()
  }, [show, userAccount])

  const computeCost = () => {
    if (!storeConfig) return '0.00000000'
    const { baseCost, costIncrement } = storeConfig
    const costNum = baseCost + costIncrement * (wantedLimits - 1)
    return costNum.toFixed(8)
  }

  const handleBuy = async () => {
    try {
      setStatus('Signing transaction...');
      
      if (!userAccount) {
        setError('User not logged in to the backend. Please log in again.')
        return
      }

      if (!session || !session.client) {
        setStatus('No wallet session found. Attempting to log in with your wallet...')
        const wharfResult = await loginWharfKit()
        setStatus('')
        if (!wharfResult || !wharfResult.success) {
          setError('Wallet session unavailable. Please log in with your wallet again.')
          return
        }
      }

      if (!storeConfig) {
        setError('Store config missing. Cannot proceed.')
        return
      }

      if (maxCanBuy <= 0) {
        setError('You already have the maximum extra-limits for today.')
        return
      }

      if (wantedLimits > maxCanBuy) {
        setError(`You can only buy up to ${maxCanBuy} more limit(s) today.`)
        return
      }

      const cost = parseFloat(computeCost())
      const burnAmount = (cost / 2).toFixed(8)
      const contractAmount = (cost / 2).toFixed(8)

      if (!session?.client?.v1?.chain) {
        throw new Error('Wallet session not ready to transact. (client.v1.chain missing)')
      }

      const info = await session.client.v1.chain.get_info()
      const header = info.getTransactionHeader()

      const transaction = {
        ...header,
        actions: [
          {
            account: 'pixelracesmg',
            name: 'transfer',
            authorization: [
              {
                actor: userAccount,
                permission: 'active'
              }
            ],
            data: {
              from: userAccount,
              to: 'eosio.null',
              quantity: `${burnAmount} PXRS`,
              memo: `buy-limit:${wantedLimits}-${userAccount}-burn`
            }
          },
          {
            account: 'pixelracesmg',
            name: 'transfer',
            authorization: [
              {
                actor: userAccount,
                permission: 'active'
              }
            ],
            data: {
              from: userAccount,
              to: 'pixelracesmg',
              quantity: `${contractAmount} PXRS`,
              memo: `buy-limit:${wantedLimits}-${userAccount}`
            }
          }
        ]
      }

      const result = await session.transact(transaction, { broadcast: true })
      if (!result?.response?.transaction_id) {
        throw new Error('Failed to broadcast transaction')
      }

      setStatus('Verifying transaction...')
      
      const buyResult = await buyRaceLimit(
        userAccount, 
        wantedLimits, 
        result.response.transaction_id,
        cost.toFixed(8)
      )

      setStatus('Purchase successful!')
      setUserBonus(buyResult.bonusRaceLimit)
      
    } catch (error) {
      console.error('Error:', error)
      setError(error.message)
    } finally {
      setStatus('')
    }
  }

  const handleClose = () => {
    setStatus('')
    setError('')
    onClose()
  }

  const userFullyMaxed = maxCanBuy <= 0

  if (!show) return null

  return (
    <>
      <div className="store-overlay" onClick={handleClose}></div>
      <div className="store-modal">
        <div className="store-modal-content">
          <button className="store-close" onClick={handleClose}>
            ✖
          </button>
          <h2 className="store-title">Extra Limits</h2>

          {!storeConfig && !error && (
            <p className="error-msg">Loading config...</p>
          )}
          {error && <p className="error-msg">{error}</p>}
          {status && <p className="status-msg">{status}</p>}

          {storeConfig && !error && (
            <>
              <div className="store-instructions">
                <p>
                  <strong>What are Extra-Limits?</strong><br />
                  Extra-limits increase the number of races you can participate in with your cars. 
                  Currently, the daily limit is 10/10 races. By purchasing +1 extra-limit, 
                  your daily race limit will become 11/11.
                </p>
              </div>

              <div className="store-info">
                <p>
                  You can buy up to {storeConfig.maxPerDay} extra-limits per day.<br />
                  Currently used today: <strong>{userBonus}</strong>
                </p>
              </div>

              {userFullyMaxed ? (
                <p style={{ color: '#fc4141' }}>
                  You have already reached {userBonus} extra-limits.
                  No more can be purchased today.
                </p>
              ) : (
                <>
                  <p style={{ fontSize: '12px', marginTop: '10px' }}>
                    You can still buy up to <strong>{maxCanBuy}</strong> limit(s).
                  </p>

                  <div style={{ margin: '20px auto' }}>
                    <label
                      htmlFor="limitsRange"
                      style={{ display: 'block', marginBottom: '5px' }}
                    >
                      +{wantedLimits} limit(s)
                    </label>
                    <input
                      id="limitsRange"
                      type="range"
                      min="1"
                      max={maxCanBuy}
                      value={wantedLimits}
                      onChange={(e) => setWantedLimits(parseInt(e.target.value, 10))}
                      style={{ width: '80%' }}
                    />
                  </div>

                  <div className="pack-option" style={{ marginTop: '20px' }}>
                    <h3>Buy +{wantedLimits} limit(s)</h3>
                    <p>Cost: {computeCost()} PXRS</p>
                    <button onClick={handleBuy}>Buy Now</button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default StoreModal