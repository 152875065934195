// raceActions.js
import { checkCarInRace } from './api';
import Cookies from 'js-cookie';
import config from '../config';

// Auxiliares
function getAuthToken() {
  return Cookies.get('authToken');
}
function getCsrfToken() {
  return Cookies.get('csrfToken');
}

const API_BASE_URL = config.apiBaseUrl;

async function getNftDetailsFromAtomicHub(nftId) {
  const ATOMIC_HUB_API_URL = 'https://wax.api.atomicassets.io/atomicassets/v1/assets/';
  try {
    const response = await fetch(`${ATOMIC_HUB_API_URL}${nftId}`);
    const data = await response.json();

    if (!data || !data.data) {
      throw new Error('NFT data not found');
    }

    const attributes = data.data.template.immutable_data;
    return {
      id: nftId,
      name: attributes.name,
      img: attributes.img,
      SpeedLevel: parseInt(attributes.SpeedLevel) || 0,
      AccelerationLevel: parseInt(attributes.AccelerationLevel) || 0,
      NitroLevel: parseInt(attributes.NitroLevel) || 0,
      LuckLevel: parseInt(attributes.LuckLevel) || 0,
      CarRarity: attributes.CarRarity || 'Common',
      SkinRarity: attributes.SkinRarity || 'Common'
    };
  } catch (error) {
    throw new Error('Failed to fetch NFT details from Atomic Hub.');
  }
}

export const startRace = async (
  selectedCar,
  selectedMap,
  userAccount,
  carUsage,
  setCarUsage,
  setRaceTime,
  setErrorMessage,
  setRacesCompleted,
  equippedNFTs
) => {
  try {
    if (!selectedCar) throw new Error('No car selected.');
    if (!selectedMap) throw new Error('No map selected.');
    if (!userAccount) throw new Error('No userAccount provided.');

    const carId = selectedCar.asset_id;
    const carTemplateId = selectedCar.template_id || selectedCar.template?.template_id;

    // Tenta usar "img2" ou fallback para "img"
    let carImageUrl = selectedCar.template?.immutable_data?.img2;
    if (!carImageUrl) {
      carImageUrl = selectedCar.template?.immutable_data?.img || '';
    }

    // Mapa
    const mapImageUrl = selectedMap.image || '';

    if (!carId) throw new Error('No carId found in selectedCar.');
    if (!carTemplateId) throw new Error('Car template ID not found.');

    // Verifica se o carro está em reparo
    const authToken = getAuthToken();
    const csrfToken = getCsrfToken();
    
    try {
      const carStatusResponse = await fetch(`${API_BASE_URL}/api/carstatus/${carId}`, {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'CSRF-Token': csrfToken
        },
        credentials: 'include'
      });
      
      if (carStatusResponse.ok) {
        const carStatus = await carStatusResponse.json();
        if (carStatus.inRepair) {
          throw new Error('This car is currently being repaired and cannot race.');
        }
      }
    } catch (error) {
      if (error.message.includes('being repaired')) {
        throw error;
      }
    }

    // Verifica se o carro já está em corrida
    const raceStatus = await checkCarInRace(carId);
    if (raceStatus.inRace) {
      throw new Error('The car is already in an ongoing race.');
    }

    // Verifica se algum item equipado está em corrida
    if (equippedNFTs && equippedNFTs.length > 0) {
      for (const item of equippedNFTs) {
        if (item && item.asset_id) {
          const itemStatus = await fetch(`${API_BASE_URL}/api/checkItemInRace/${item.asset_id}`, {
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'CSRF-Token': csrfToken
            },
            credentials: 'include'
          });
          
          if (itemStatus.ok) {
            const { inRace } = await itemStatus.json();
            if (inRace) {
              throw new Error(`Cannot start race: Equipment item #${item.asset_id} is currently being used in another race.`);
            }
          }
        }
      }
    }

    // Checa uso (limite local)
    const currentTime = new Date().toISOString();
    const usageInfo = carUsage[carId] || { count: 0, lastUsed: currentTime };

    if (
      usageInfo.count >= 10 &&
      new Date().getTime() - new Date(usageInfo.lastUsed).getTime() < 12 * 60 * 60 * 1000
    ) {
      throw new Error('Usage limit for this car reached.');
    }

    // Buscar detalhes do carro (opcional)
    let carNftDetails = null;
    try {
      carNftDetails = await getNftDetailsFromAtomicHub(carId);
    } catch (error) {
      // Optional NFT details not found
    }

    // Montar array de itens equipados
    const itemsWithIds = equippedNFTs
      .filter((i) => i !== null)
      .map((item) => ({
        asset_id: item.asset_id,
        collectionName: item.collection?.collection_name,
        templateId: item.template?.template_id,
        victoryChance: item.victoryChance,
        dropChance: item.dropChance,
        xpBonus: item.xpBonus || 0,
        img: item.template?.immutable_data?.img || ''
      }));

    const raceData = {
      walletAddress: userAccount,
      carId,
      carTemplateId,
      carImageUrl,
      mapId: selectedMap._id,
      mapImageUrl,
      startTime: currentTime,
      duration: selectedMap.raceTime, 
      carNftDetails,
      equippedItems: itemsWithIds
    };

    // Garantir que o walletAddress está sendo enviado
    if (!userAccount) {
      throw new Error('User account is required');
    }

    const response = await fetch(`${API_BASE_URL}/api/protected/start-race`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify(raceData)
    });

    if (!response.ok) {
      let errorMsg = 'Failed to start the race.';
      try {
        const errorData = await response.json();
        errorMsg = errorData.message || errorMsg;
      } catch (err2) {
        // parse error
      }
      throw new Error(errorMsg);
    }

    const data = await response.json();

    // Atualiza tempo e uso local
    setRaceTime(Number(selectedMap.raceTime) || 0);
    setCarUsage({
      ...carUsage,
      [carId]: {
        count: usageInfo.count + 1,
        lastUsed: new Date().toISOString(),
        raceTime: selectedMap.raceTime
      }
    });

    if (data.racesCompleted !== undefined) {
      setRacesCompleted(data.racesCompleted);
    }

    return data;
  } catch (error) {
    throw error;
  }
};
