import React, { useState, useEffect } from 'react'
import '../styles/oficinaModal.css'
import { TailSpin } from 'react-loader-spinner'
import { useAuth } from '../contexts/AuthContext'
import MechanicSection from './MechanicSection'
import {
  fetchDamagedCarsByUser,
  checkCarsInRaceBatch,
  calculateRepairCost,
  repairCar,
  getTotalRepairCost,
  repairAllCars,
  fetchNFTs,
  addCarToMechanic,
  getActiveMechanic,
  updateMechanicSettings,
  verifyAndUpdateCarOwnership
} from '../utils/api'
import Notification from './Notification'

const OficinaModal = ({
  showOficina,
  setShowOficina,
  userAccount
}) => {
  const { session, loginWharfKit } = useAuth()

  const [cars, setCars] = useState([])
  const [raceStatusMap, setRaceStatusMap] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [statusMessage, setStatusMessage] = useState('')
  const [totalCost, setTotalCost] = useState('0.00000000')
  const [carsInRepair, setCarsInRepair] = useState(new Set())
  const [notification, setNotification] = useState({ show: false, message: '' })
  const [showMechanics, setShowMechanics] = useState(true)
  const [autoRepairSettings, setAutoRepairSettings] = useState({
    enabled: false,
    statuses: {
      ok: false,
      needs_repair: true,
      broken: true
    },
    rarities: {
      common: false,
      uncommon: false,
    }
  })

  useEffect(() => {
    if (userAccount && showOficina) {
      loadDamagedCars();
      loadTotalCost();
    }
  }, [userAccount, showOficina]);

  if (!showOficina) return null

  // Load individual cars & their costs
  const loadDamagedCars = async () => {
    try {
      if (!userAccount) {
        setErrorMessage('Please login to view your damaged cars');
        return;
      }

      setErrorMessage('');
      setStatusMessage('Loading damaged cars...');
      
      const damagedCars = await fetchDamagedCarsByUser(userAccount);
      
      if (!damagedCars || damagedCars.length === 0) {
        setCars([]);
        setStatusMessage('No damaged cars found');
        return;
      }

      setCars(damagedCars);
      setStatusMessage('');
    } catch (err) {
      if (err.message.includes('Access Denied') || err.message.includes('Invalid Token')) {
        setErrorMessage('Session expired. Please login again.');
      } else {
        setErrorMessage(err.message || 'Failed to load damaged cars');
      }
      setStatusMessage('');
    }
  };

  // Load total cost for all cars
  const loadTotalCost = async () => {
    try {
      setErrorMessage('')
      setStatusMessage('Calculating total repair cost...')
      const data = await getTotalRepairCost(userAccount)
      setTotalCost(data.cost || '0.00000000')
      setStatusMessage('')
    } catch (err) {
      setErrorMessage('Failed to load total repair cost')
      setStatusMessage('')
    }
  }

  const handleRefresh = async () => {
    try {
      setIsLoading(true);
      showNotification('Checking car ownership...');
      
      const result = await verifyAndUpdateCarOwnership();
      if (result.message) {
        showNotification(result.message);
      }

      await loadDamagedCars();
      await loadTotalCost();
    } catch (error) {
      showNotification(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Single-car repair
  const handlePayAndRepair = async (car) => {
    try {
      const costResult = await calculateRepairCost(car.carId);
      const repairCost = costResult.cost;

      if (!session?.client?.v1?.chain) {
        throw new Error('Wallet session not ready to transact. (client.v1.chain missing)');
      }

      const info = await session.client.v1.chain.get_info();
      const header = info.getTransactionHeader();

      const burnAmount = (parseFloat(repairCost) / 2).toFixed(8);
      const contractAmount = (parseFloat(repairCost) / 2).toFixed(8);

      const transaction = {
        ...header,
        actions: [
          {
            account: 'pixelracesmg',
            name: 'transfer',
            authorization: [{
              actor: userAccount,
              permission: 'active',
            }],
            data: {
              from: userAccount,
              to: 'eosio.null',
              quantity: `${burnAmount} PXRS`,
              memo: `repair:${car.carId}-${userAccount}-burn`
            },
          },
          {
            account: 'pixelracesmg',
            name: 'transfer',
            authorization: [{
              actor: userAccount,
              permission: 'active',
            }],
            data: {
              from: userAccount,
              to: 'pixelracesmg',
              quantity: `${contractAmount} PXRS`,
              memo: `repair:${car.carId}-${userAccount}`
            },
          }
        ]
      };

      showNotification('Please confirm the transaction in your wallet...');
      const result = await session.transact(transaction, { broadcast: true });

      const transactionId = result?.resolved?.transaction?.id;
      if (!transactionId) {
        throw new Error('Transaction ID not found in response');
      }

      showNotification('Please wait while we process your repair...');
      const repairResult = await repairCar({
        carId: car.carId,
        walletAddress: userAccount,
        transactionId
      });

      if (repairResult) {
        showNotification('Car repaired successfully!');
        await loadDamagedCars();
        await loadTotalCost();
      }
    } catch (error) {
      showNotification(error.message);
    }
  };

  // Single TX for all cars
  const handleRepairAll = async () => {
    try {
      setIsLoading(true);
      const totalCost = await getTotalRepairCost(userAccount);
      
      if (!totalCost || totalCost.cost <= 0) {
        showNotification('No cars need repair');
        return;
      }

      if (!session?.client?.v1?.chain) {
        throw new Error('Wallet session not ready to transact. (client.v1.chain missing)');
      }

      const info = await session.client.v1.chain.get_info();
      const header = info.getTransactionHeader();

      // Dividir o custo em duas partes iguais
      const burnAmount = (parseFloat(totalCost.cost) / 2).toFixed(8);
      const contractAmount = (parseFloat(totalCost.cost) / 2).toFixed(8);

      const transaction = {
        ...header,
        actions: [
          {
            account: 'pixelracesmg',
            name: 'transfer',
            authorization: [{
              actor: userAccount,
              permission: 'active',
            }],
            data: {
              from: userAccount,
              to: 'eosio.null',
              quantity: `${burnAmount} PXRS`,
              memo: `repairAll:${userAccount}-burn`
            },
          },
          {
            account: 'pixelracesmg',
            name: 'transfer',
            authorization: [{
              actor: userAccount,
              permission: 'active',
            }],
            data: {
              from: userAccount,
              to: 'pixelracesmg',
              quantity: `${contractAmount} PXRS`,
              memo: `repairAll:${userAccount}`
            },
          }
        ]
      };

      showNotification('Please confirm the transaction in your wallet...');
      const result = await session.transact(transaction, { broadcast: true });

      // Verificar se temos o ID da transação
      const transactionId = result?.resolved?.transaction?.id;
      if (!transactionId) {
        throw new Error('Transaction ID not found in response');
      }

      // Após confirmação da transação, repara todos os carros
      showNotification('Please wait while we process your repairs...');
      const repairResult = await repairAllCars(userAccount, totalCost.cost, transactionId);

      if (repairResult) {
        showNotification(`Successfully repaired ${repairResult.repairedCount} cars!`);
        // Atualizar a lista de carros danificados
        await loadDamagedCars();
        await loadTotalCost();
      }
    } catch (error) {
      showNotification(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddToMechanic = async (car) => {
    try {
      await addCarToMechanic(car.carId);
      await loadDamagedCars();
      showNotification('Car added to mechanic successfully');
    } catch (error) {
      showNotification(error.message);
    }
  };

  const showNotification = (message) => {
    setNotification({ show: true, message })
    setTimeout(() => setNotification(prev => ({ ...prev, show: false, message: '' })), 3000)
  }

  const handleError = (error) => {
    showNotification(error.message || 'An error occurred')
  }

  const handleAutoRepairToggle = () => {
    setAutoRepairSettings(prev => ({
      ...prev,
      enabled: !prev.enabled
    }));
  };

  const handleStatusToggle = (status) => {
    setAutoRepairSettings(prev => ({
      ...prev,
      statuses: {
        ...prev.statuses,
        [status]: !prev.statuses[status]
      }
    }));
  };

  const saveAutoRepairSettings = async () => {
    try {
      await updateMechanicSettings(userAccount, {
        autoRepairEnabled: autoRepairSettings.enabled,
        autoRepairStatuses: autoRepairSettings.statuses,
        autoRepairRarities: autoRepairSettings.rarities
      });
      showNotification('Auto-repair settings saved!', 'success');
    } catch (error) {
      showNotification('Failed to save settings', 'error');
    }
  };

  return (
    <>
      {notification.show && (
        <Notification 
          message={notification.message} 
          setShowNotification={(show) => setNotification(prev => ({ ...prev, show }))}
        />
      )}
      <div className="oficina-overlay" onClick={() => setShowOficina(false)}></div>
      <div className="oficina-modal show">
        {/* Close button */}
        <button
          className="oficina-close-button"
          onClick={() => setShowOficina(false)}
        >
          ✖
        </button>

        {/* Header */}
        <div className="oficina-header">
          <h2>AUTO REPAIR SHOP</h2>
        </div>

        <div className="oficina-content">
          {errorMessage && <p className="oficina-error">{errorMessage}</p>}
          {statusMessage && <p className="oficina-status">{statusMessage}</p>}

          {/* Seção de Mecânicos */}
          {showMechanics && (
            <MechanicSection 
              userAccount={userAccount} 
              onError={(msg) => setErrorMessage(msg)}
            />
          )}

          {/* Container for total cost + repair all + refresh */}
          <div className="repair-all-container">
            <div className="total-cost-label">
              Total Cost for All Damages:
              <span className="total-cost-value"> {totalCost} PXRS</span>
            </div>

            {/* "Repair All" in single transaction */}
            <button
              className="repair-all-button"
              onClick={handleRepairAll}
            >
              Repair ALL Cars
            </button>

            {/* Refresh button on the same line, if you prefer */}
            <button
              className="refresh-button-inline"
              onClick={handleRefresh}
              title="Refresh"
            >
              ⟳
            </button>
          </div>

          {isLoading ? (
            <div className="oficina-loading-container">
              <TailSpin height="50" width="50" color="#ff0000" ariaLabel="loading" />
            </div>
          ) : cars.length === 0 ? (
            <div className="oficina-no-cars">
              You have no damaged cars!
            </div>
          ) : (
            <div className="oficina-cards-container">
              {cars.map((car, idx) => {
                const isInRace = !!raceStatusMap[car.carId]
                const isInRepair = carsInRepair.has(car.carId)
                const cardClassName = isInRace
                  ? 'oficina-card in-race'
                  : isInRepair
                  ? 'oficina-card in-repair'
                  : 'oficina-card'

                return (
                  <div key={idx} className={cardClassName}>
                    {/* Car image */}
                    <div className="oficina-card-image">
                      <img
                        src={`https://ipfs.neftyblocks.io/ipfs/${car.template?.immutable_data?.img2}`}
                        alt={car.template?.immutable_data?.name || 'Car'}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/default-car.png';
                        }}
                      />
                    </div>

                    <div className="oficina-card-info">
                      {/* Name + Rarity in one line */}
                      <div className="oficina-card-name-rarity">
                        <strong>Name:</strong> {car.carName || 'Unknown'}
                        &nbsp;(<span className="rarity">{car.rarity || 'common'}</span>)
                      </div>

                      {/* ID + damage + status in minimal lines */}
                      <div className="oficina-card-id">
                        <strong>ID:</strong> {car.carId}
                      </div>
                      <div className="oficina-card-damage-status">
                        <strong>Damage:</strong> {car.totalDamage}
                        &nbsp;({car.damageStatus})
                      </div>

                      {/* Parts Damaged in <details> */}
                      {car.damages.length > 0 && (
                        <details className="parts-damaged-details">
                          <summary>Parts Damaged ({car.damages.length})</summary>
                          <ul>
                            {car.damages.map((part, pIdx) => (
                              <li key={pIdx}>
                                {part.partName}: <strong>{part.damagePoints}</strong>
                              </li>
                            ))}
                          </ul>
                        </details>
                      )}

                      {/* Repair cost (above the button) */}
                      <div className="oficina-card-cost">
                        <strong>Repair Cost:</strong> {car.repairCost} PXRS
                      </div>

                      {/* Button or in-race message */}
                      {isInRace ? (
                        <div className="oficina-card-inrace">
                          Car is in Race!
                        </div>
                      ) : (
                        <button
                          className="oficina-repair-button"
                          onClick={() => handlePayAndRepair(car)}
                        >
                          Repair with PXRS
                        </button>
                      )}

                      {/* Adicionar botão para enviar ao mecânico */}
                      {car.totalDamage > 0 && (
                        <button
                          onClick={() => handleAddToMechanic(car)}
                          className="send-to-mechanic-btn"
                          disabled={isInRepair}
                        >
                          {isInRepair ? 'In Repair' : 'Send to Mechanic'}
                        </button>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default OficinaModal
