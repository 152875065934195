import React from 'react';

const CarAttributes = ({ carToShowAttributes, selectedCars, isVisible }) => {
  return (
    <div className={`attributes ${isVisible ? 'visible' : ''}`}>
      {selectedCars.length === 0 ? (
        <div className="attributes-content">
          <div className="attribute">No car selected</div>
        </div>
      ) : carToShowAttributes ? (
        <div className="attributes-content">
          <div className="attribute">
            Name: {carToShowAttributes.template.immutable_data.name} (ID: {carToShowAttributes.asset_id})
          </div>
          <div className="attribute">
            Car Rarity: {carToShowAttributes.template.immutable_data['Car Rarity']}
          </div>
          <div className="attribute">
            Skin Rarity: {carToShowAttributes.template.immutable_data['Skin Rarity']}
          </div>
          <div className="attribute">
            Speed Level: {carToShowAttributes.template.immutable_data['Speed Level']}
          </div>
          <div className="attribute">
            Acceleration: {carToShowAttributes.template.immutable_data['Acceleration']}
          </div>
          <div className="attribute">
            Nitro Level: {carToShowAttributes.template.immutable_data['Nitro Level']}
          </div>
          <div className="attribute">
            Luck Level: {carToShowAttributes.template.immutable_data['Luck Level']}
          </div>
          <div className="attribute">
            Special Edition: {carToShowAttributes.template.immutable_data['Special Edition'] || 'No'}
          </div>
          <div className="attribute">
            Kilometers: {carToShowAttributes.mutable_data?.['Kilometers'] || '0'} km
          </div>
        </div>
      ) : (
        <div className="attributes-content">
          <div className="attribute">Click on a car slot to view its attributes.</div>
        </div>
      )}
    </div>
  );
};

export default CarAttributes; 