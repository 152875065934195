import React, { useState } from 'react';
import './Footer.css';
import atomicHubIcon from '../assets/atomic-hub-icon.png'; // Certifique-se de que o caminho está correto
import xTwitterIcon from '../assets/x-twitter-icon.png';

const Footer = () => {
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false);

  const openDisclaimer = () => {
    setIsDisclaimerOpen(true);
  };

  const closeDisclaimer = () => {
    setIsDisclaimerOpen(false);
  };

  // Função para renderizar pixels caindo
  const renderFallingPixels = () => {
    const pixels = [];
    const totalPixels = 10; // Aumente o número de pixels
  
    for (let i = 0; i < totalPixels; i++) {
      const style = {
        left: `${(i / totalPixels) * 110}%`, // Distribuição uniforme ao longo do footer
        animationDuration: `${2 + Math.random() * 3}s`,
      };
      pixels.push(<div key={i} className="pixel" style={style}></div>);
    }
    return pixels;
  };

  return (
    <footer className="footer">
      <div className="footer-column-left">
        {/* Você pode adicionar conteúdo na coluna esquerda se precisar */}
      </div>
      <div className="footer-column-center">
        <p className="copyright-text">&copy; 2025 Pixel Races. All rights reserved.</p>
      </div>
      <div className="footer-column-right">
        <div className="social-icons">
          <a href="https://x.com/pixelracesgo" target="_blank" rel="noopener noreferrer" className="x-twitter-icon">
            <img src={xTwitterIcon} alt="X (Twitter)" className="social-icon" />
          </a>
          <a href="https://atomichub.io/explorer/collection/wax-mainnet/pixelracesgo" target="_blank" rel="noopener noreferrer" className="atomic-hub-icon">
            <img src={atomicHubIcon} alt="AtomicHub" className="social-icon" />
          </a>
        </div>
      </div>
      {renderFallingPixels()} {/* Adiciona os pixels caindo ao footer */}
      <span className="disclaimer-text" onClick={openDisclaimer}>
        Disclaimer
      </span>
      {isDisclaimerOpen && (
        <div className="disclaimer-modal">
          <div className="disclaimer-content">
            <span className="close-button" onClick={closeDisclaimer}>&times;</span>
            <h2 className="disclaimer-title">Disclaimer</h2>
            <p className="disclaimer-paragraph">
              Pixel Races is not an investment. Please be aware that purchasing NFTs and engaging in this game does not constitute an investment and should not be considered as such. We are not responsible for any financial decisions you make related to the use of Pixel Races.
            </p>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
