import React, { createContext, useState, useContext, useEffect } from 'react'
import Cookies from 'js-cookie'
import config from '../config'
import endpoints from '../utils/endpoints'
import {jwtDecode} from 'jwt-decode'

// WharfKit dependencies
import { SessionKit } from '@wharfkit/session'
import { WebRenderer } from '@wharfkit/web-renderer'
import { WalletPluginAnchor } from '@wharfkit/wallet-plugin-anchor'
import { WalletPluginCloudWallet } from '@wharfkit/wallet-plugin-cloudwallet'
import { WalletPluginWombat } from '@wharfkit/wallet-plugin-wombat'

const AuthContext = createContext()

export function AuthProvider({ children }) {
  const [userAccount, setUserAccount] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const [racingCoins, setRacingCoins] = useState(0)
  const [loading, setLoading] = useState(true)

  // sessionKit = WharfKit instance
  const [sessionKit, setSessionKit] = useState(null)
  // session = user's logged session (has .transact, etc.)
  const [session, setSession] = useState(null)

  // Initialize sessionKit + restore if there's any stored session
  useEffect(() => {
    const initializeSessionKit = () => {
      const ui = new WebRenderer()
      const anchor = new WalletPluginAnchor()
      const cloudWallet = new WalletPluginCloudWallet({
        supportedChains: [endpoints.CHAIN.id],
        url: endpoints.CLOUD_WALLET.url,
        autoUrl: endpoints.CLOUD_WALLET.autoUrl,
        loginTimeout: endpoints.CLOUD_WALLET.loginTimeout
      })

      // Including Wombat directly
      const wombat = new WalletPluginWombat({
        chainId: endpoints.WOMBAT.chainId,
        chainUrl: endpoints.WOMBAT.chainUrl,
      });
      const walletPlugins = [anchor, cloudWallet, wombat]

      const kit = new SessionKit({
        appName: 'Pixel Races',
        chains: [
          {
            id: endpoints.CHAIN.id,
            url: endpoints.CHAIN.url
          }
        ],
        ui,
        walletPlugins
      })

      setSessionKit(kit)

      const restoreSession = async () => {
        try {
          const restored = await kit.restore()
          if (restored && restored.permissionLevel) {
            const newSession = restored.session || restored
            setSession(newSession)

            const walletAddress = newSession.permissionLevel.actor.toString()

            const response = await fetch(`${config.apiBaseUrl}/api/auth/login`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ walletAddress }),
              credentials: 'include'
            })
            if (response.ok) {
              const data = await response.json()
              Cookies.set('authToken', data.token, { expires: 7 })
              setRacingCoins(data.racingCoins)
              const decoded = jwtDecode(data.token)
              loginUser(decoded.wallet, decoded.isAdmin, data.racingCoins, newSession)
            }
          }
        } catch (err) {
          // Error handling without console.log
        } finally {
          setLoading(false)
        }
      }

      restoreSession()
    }

    initializeSessionKit()
  }, [])

  const loginUser = (walletAddress, isAdmin, racingCoins, session) => {
    setUserAccount(walletAddress);
    setIsAdmin(isAdmin);
    setRacingCoins(racingCoins);
    if (session) setSession(session);
  };

  const logoutUser = async () => {
    if (sessionKit) {
      try {
        await sessionKit.logout();
      } catch (err) {
        // Error handling without console.log
      }
    }

    setUserAccount(null);
    setIsAdmin(false);
    setRacingCoins(0);
    setSession(null);

    Cookies.remove('authToken');
    Cookies.remove('csrfToken');

    localStorage.removeItem('carUsage');
    localStorage.removeItem('selectedProfileImage');

    try {
      await fetch(`${config.apiBaseUrl}/api/auth/logout`, {
        method: 'POST',
        credentials: 'include'
      });
    } catch (err) {
      // Error handling without console.log
    }
  };

  const loginWharfKit = async () => {
    if (!sessionKit) {
      return { success: false }
    }
    try {
      const loginResult = await sessionKit.login()
      if (loginResult && loginResult.permissionLevel) {
        const newSession = loginResult.session || loginResult
        const walletAddress = newSession.permissionLevel.actor.toString()

        const response = await fetch(`${config.apiBaseUrl}/api/auth/login`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ walletAddress }),
          credentials: 'include'
        })

        if (response.ok) {
          const data = await response.json()
          Cookies.set('authToken', data.token, { expires: 7 })
          setRacingCoins(data.racingCoins)
          const decoded = jwtDecode(data.token)
          loginUser(decoded.wallet, decoded.isAdmin, data.racingCoins, newSession)
          return { success: true }
        }
      }
      return { success: false }
    } catch (err) {
      return { success: false }
    }
  }

  const updateRacingCoins = (coins) => {
    setRacingCoins(coins)
  }

  // Values that will be available through the context
  const value = {
    userAccount,
    isAdmin,
    racingCoins,
    setRacingCoins: updateRacingCoins,
    loading,
    sessionKit,
    session,
    setSession,
    login: loginUser,
    logout: logoutUser,
    loginWharfKit
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
