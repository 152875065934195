import React, { useState, useEffect } from 'react';
import config from '../../config';
import { getAuthToken, getCsrfToken } from '../../utils/auth';

const NewsManagement = () => {
  const [news, setNews] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    imageUrl: '',
    featured: false
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    loadNews();
  }, []);

  const loadNews = async () => {
    try {
      const token = getAuthToken();
      const response = await fetch(`${config.apiBaseUrl}/api/news/admin/all`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (data.success) {
        setNews(data.news);
      }
    } catch (error) {
      console.error('Error loading news:', error);
      setError('Error loading news');
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        setError('A imagem deve ter menos de 5MB');
        return;
      }
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setFormData(prev => ({
          ...prev,
          imageUrl: reader.result
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const token = getAuthToken();
      if (!token) {
        setError('Authentication required');
        return;
      }

      if (!formData.imageUrl && !editMode) {
        setError('Por favor selecione uma imagem');
        return;
      }

      // Se esta é uma notícia em destaque, primeiro removemos o destaque das outras
      if (formData.featured) {
        await fetch(`${config.apiBaseUrl}/api/news/unfeatured-all`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
      }

      const url = editMode 
        ? `${config.apiBaseUrl}/api/news/${currentId}`
        : `${config.apiBaseUrl}/api/news`;

      const method = editMode ? 'PUT' : 'POST';

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          ...formData,
          imageUrl: formData.imageUrl
        })
      });

      const data = await response.json();
      if (data.success) {
        loadNews();
        resetForm();
      } else {
        setError(data.message || 'Error saving news');
      }
    } catch (error) {
      console.error('Error saving news:', error);
      setError('Error saving news');
    }
  };

  const handleEdit = (newsItem) => {
    setFormData({
      title: newsItem.title,
      content: newsItem.content,
      imageUrl: newsItem.imageUrl,
      featured: newsItem.featured
    });
    setImagePreview(newsItem.imageUrl);
    setCurrentId(newsItem._id);
    setEditMode(true);
  };

  const handleDelete = async (id) => {
    try {
      const token = getAuthToken();
      const response = await fetch(`${config.apiBaseUrl}/api/news/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const data = await response.json();
      if (data.success) {
        loadNews();
      } else {
        setError(data.message || 'Error deleting news');
      }
    } catch (error) {
      console.error('Error deleting news:', error);
      setError('Error deleting news');
    }
  };

  const resetForm = () => {
    setFormData({
      title: '',
      content: '',
      imageUrl: '',
      featured: false
    });
    setSelectedImage(null);
    setImagePreview('');
    setCurrentId(null);
    setEditMode(false);
    setError('');
  };

  return (
    <div className="news-management" style={{ padding: '20px' }}>
      <h2>News Management</h2>
      {error && <div className="error-message" style={{ color: 'red', marginBottom: '10px' }}>{error}</div>}
      
      <form onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: '0 auto' }}>
        <div style={{ marginBottom: '15px' }}>
          <label>Title:</label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            required
            style={{ width: '100%', padding: '8px', marginTop: '5px' }}
          />
        </div>
        
        <div style={{ marginBottom: '15px' }}>
          <label>Content:</label>
          <textarea
            name="content"
            value={formData.content}
            onChange={handleInputChange}
            required
            style={{ width: '100%', padding: '8px', marginTop: '5px', minHeight: '150px' }}
          />
        </div>
        
        <div style={{ marginBottom: '15px' }}>
          <label>Image:</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="file-input"
            style={{ 
              width: '100%', 
              padding: '8px', 
              marginTop: '5px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              backgroundColor: '#fff'
            }}
          />
          {imagePreview && (
            <div className="image-preview" style={{ marginTop: '10px', textAlign: 'center' }}>
              <img 
                src={imagePreview} 
                alt="Preview" 
                style={{ 
                  maxWidth: '200px', 
                  maxHeight: '200px',
                  objectFit: 'contain',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  padding: '5px'
                }} 
              />
            </div>
          )}
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <input
              type="checkbox"
              name="featured"
              checked={formData.featured}
              onChange={handleInputChange}
            />
            Featured News (Will be shown on startup)
          </label>
        </div>
        
        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
          <button 
            type="submit"
            style={{
              padding: '10px 20px',
              backgroundColor: '#F5130D',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            {editMode ? 'Update' : 'Create'} News
          </button>
          {editMode && (
            <button 
              type="button" 
              onClick={resetForm}
              style={{
                padding: '10px 20px',
                backgroundColor: '#666',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
            >
              Cancel
            </button>
          )}
        </div>
      </form>

      <div className="news-list" style={{ marginTop: '30px' }}>
        <h3>Existing News</h3>
        {news.map(item => (
          <div key={item._id} className="news-item" style={{ 
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '15px',
            marginBottom: '15px'
          }}>
            <h4>{item.title}</h4>
            <p>{item.content.substring(0, 100)}...</p>
            {item.imageUrl && (
              <img 
                src={item.imageUrl} 
                alt={item.title} 
                style={{ 
                  maxWidth: '100px',
                  maxHeight: '100px',
                  objectFit: 'contain',
                  marginTop: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  padding: '5px'
                }} 
              />
            )}
            {item.featured && (
              <span className="featured-badge" style={{
                backgroundColor: '#F5130D',
                color: 'white',
                padding: '4px 8px',
                borderRadius: '4px',
                fontSize: '12px',
                marginLeft: '10px'
              }}>
                Featured
              </span>
            )}
            <div className="news-actions" style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
              <button 
                onClick={() => handleEdit(item)}
                style={{
                  padding: '5px 10px',
                  backgroundColor: '#F5130D',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer'
                }}
              >
                Edit
              </button>
              <button 
                onClick={() => handleDelete(item._id)}
                style={{
                  padding: '5px 10px',
                  backgroundColor: '#666',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer'
                }}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsManagement; 