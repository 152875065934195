import React, { useState, useEffect } from 'react';
import { fetchDamageConfig, updateDamageConfig } from '../../utils/api';
import styled from 'styled-components';

const StyledContainer = styled.div`
  padding: 20px;
  background: #f5f5f5;
  border-radius: 8px;

  .damage-section {
    margin-bottom: 30px;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }

  h3 {
    color: #333;
    margin-bottom: 20px;
  }

  h4 {
    color: #666;
    margin-bottom: 15px;
  }

  .config-field {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  label {
    min-width: 150px;
    margin-right: 10px;
  }

  input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100px;
  }

  .intensity-config {
    margin-bottom: 20px;
    padding: 15px;
    background: #f9f9f9;
    border-radius: 6px;
  }

  .part-config {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    padding: 10px;
    background: #f9f9f9;
    border-radius: 6px;
  }

  button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .save-config-button {
    background-color: #4CAF50;
    color: white;
    font-weight: bold;
    margin-top: 20px;
  }

  .add-part-button {
    background-color: #2196F3;
    color: white;
    margin-bottom: 15px;
  }

  .remove-part-button {
    background-color: #f44336;
    color: white;
  }

  button:hover {
    opacity: 0.9;
  }
`;

const DamageConfigSettings = () => {
  const [damageConfig, setDamageConfig] = useState({
    intensities: {
      light: { min: 5, max: 15 },
      moderate: { min: 10, max: 25 },
      extreme: { min: 20, max: 40 }
    },
    rarityResistance: {
      common: 1,
      uncommon: 0.9,
      rare: 0.8,
      epic: 0.7,
      legendary: 0.6,
      mythic: 0.5,
      exotic: 0.4,
      ultimate: 0.3
    },
    weightedParts: [
      { name: 'Tires', weight: 4 },
      { name: 'Brakes', weight: 3 },
      { name: 'Suspension', weight: 3 },
      { name: 'Engine', weight: 2 },
      { name: 'Transmission', weight: 2 },
      { name: 'Steering', weight: 2 },
      { name: 'Electrical System', weight: 2 },
      { name: 'Cooling System', weight: 1 },
      { name: 'Body', weight: 1 },
      { name: 'Windows', weight: 1 }
    ]
  });

  useEffect(() => {
    const fetchConfigs = async () => {
      try {
        const response = await fetchDamageConfig();
        if (response.success && response.damageConfig) {
          setDamageConfig(response.damageConfig);
        }
      } catch (error) {
        console.error('Erro ao carregar configurações de dano:', error);
      }
    };
    fetchConfigs();
  }, []);

  const handleIntensityChange = (intensity, field, value) => {
    setDamageConfig(prev => ({
      ...prev,
      intensities: {
        ...prev.intensities,
        [intensity]: {
          ...prev.intensities[intensity],
          [field]: Number(value)
        }
      }
    }));
  };

  const handleRarityChange = (rarity, value) => {
    setDamageConfig(prev => ({
      ...prev,
      rarityResistance: {
        ...prev.rarityResistance,
        [rarity]: Number(value)
      }
    }));
  };

  const handlePartWeightChange = (index, value) => {
    setDamageConfig(prev => ({
      ...prev,
      weightedParts: prev.weightedParts.map((part, i) => 
        i === index ? { ...part, weight: Number(value) } : part
      )
    }));
  };

  const handlePartNameChange = (index, value) => {
    setDamageConfig(prev => ({
      ...prev,
      weightedParts: prev.weightedParts.map((part, i) => 
        i === index ? { ...part, name: value } : part
      )
    }));
  };

  const addNewPart = () => {
    setDamageConfig(prev => ({
      ...prev,
      weightedParts: [...prev.weightedParts, { name: '', weight: 1 }]
    }));
  };

  const removePart = (index) => {
    setDamageConfig(prev => ({
      ...prev,
      weightedParts: prev.weightedParts.filter((_, i) => i !== index)
    }));
  };

  const saveConfigs = async () => {
    try {
      const response = await updateDamageConfig(damageConfig);
      if (response.success) {
        alert('Configurações de dano salvas com sucesso!');
      } else {
        throw new Error(response.message || 'Erro ao salvar configurações');
      }
    } catch (error) {
      console.error('Erro ao salvar configurações de dano:', error);
      alert('Erro ao salvar configurações de dano: ' + error.message);
    }
  };

  return (
    <StyledContainer>
      <h3>Configurações de Dano</h3>
      
      <div className="damage-section">
        <h4>Intensidades de Dano</h4>
        {Object.entries(damageConfig.intensities).map(([intensity, values]) => (
          <div key={intensity} className="intensity-config">
            <h5>{intensity.charAt(0).toUpperCase() + intensity.slice(1)}</h5>
            <div className="config-field">
              <label>Dano Mínimo:</label>
              <input
                type="number"
                value={values.min}
                onChange={(e) => handleIntensityChange(intensity, 'min', e.target.value)}
              />
            </div>
            <div className="config-field">
              <label>Dano Máximo:</label>
              <input
                type="number"
                value={values.max}
                onChange={(e) => handleIntensityChange(intensity, 'max', e.target.value)}
              />
            </div>
          </div>
        ))}
      </div>

      <div className="damage-section">
        <h4>Multiplicadores de Resistência por Raridade</h4>
        {Object.entries(damageConfig.rarityResistance).map(([rarity, value]) => (
          <div key={rarity} className="config-field">
            <label>{rarity.charAt(0).toUpperCase() + rarity.slice(1)}:</label>
            <input
              type="number"
              step="0.1"
              min="0"
              max="1"
              value={value}
              onChange={(e) => handleRarityChange(rarity, e.target.value)}
            />
          </div>
        ))}
      </div>

      <div className="damage-section">
        <h4>Partes e Pesos</h4>
        <button onClick={addNewPart} className="add-part-button">
          Adicionar Nova Parte
        </button>
        {damageConfig.weightedParts.map((part, index) => (
          <div key={index} className="part-config">
            <div className="config-field">
              <label>Nome da Parte:</label>
              <input
                type="text"
                value={part.name}
                onChange={(e) => handlePartNameChange(index, e.target.value)}
              />
            </div>
            <div className="config-field">
              <label>Peso:</label>
              <input
                type="number"
                min="1"
                max="10"
                value={part.weight}
                onChange={(e) => handlePartWeightChange(index, e.target.value)}
              />
            </div>
            <button 
              onClick={() => removePart(index)}
              className="remove-part-button"
            >
              Remover
            </button>
          </div>
        ))}
      </div>

      <button onClick={saveConfigs} className="save-config-button">
        Salvar Configurações
      </button>
    </StyledContainer>
  );
};

export default DamageConfigSettings; 