import React, { useState, useEffect } from 'react';
import { fetchRepairConfig, updateRepairConfig } from '../../utils/api';

const RepairConfigSettings = () => {
  const [repairConfig, setRepairConfig] = useState({
    costPerDamagePoint: 0.075,
    rarityPrices: {
      common: 0.075,
      uncommon: 0.15,
      rare: 0.3,
      epic: 0.6,
      legendary: 1.2,
      mythic: 2.4,
      exotic: 4.8,
      ultimate: 9.6
    }
  });

  useEffect(() => {
    const fetchConfigs = async () => {
      try {
        const data = await fetchRepairConfig();
        if (data.repairConfig) setRepairConfig(data.repairConfig);
      } catch (error) {
        console.error('Erro ao carregar configurações de reparo:', error);
      }
    };
    fetchConfigs();
  }, []);

  const handleRarityPriceChange = (rarity, value) => {
    setRepairConfig(prev => ({
      ...prev,
      rarityPrices: {
        ...prev.rarityPrices,
        [rarity]: Number(value)
      }
    }));
  };

  const saveConfigs = async () => {
    try {
      await updateRepairConfig(repairConfig);
      alert('Configurações de reparo salvas com sucesso!');
    } catch (error) {
      console.error('Erro ao salvar configurações de reparo:', error);
      alert('Erro ao salvar configurações de reparo');
    }
  };

  return (
    <div className="repair-config-settings">
      <h3>Configurações de Reparo</h3>
      
      <div className="repair-section">
        <h4>Custo por Ponto de Dano (PXRS)</h4>
        <div className="config-field">
          <label>Custo Base:</label>
          <input
            type="number"
            step="0.001"
            value={repairConfig.costPerDamagePoint}
            onChange={(e) => setRepairConfig(prev => ({
              ...prev,
              costPerDamagePoint: Number(e.target.value)
            }))}
          />
        </div>
      </div>

      <div className="repair-section">
        <h4>Custo por Raridade (PXRS por ponto de dano)</h4>
        {Object.entries(repairConfig.rarityPrices).map(([rarity, price]) => (
          <div key={rarity} className="config-field">
            <label>{rarity.charAt(0).toUpperCase() + rarity.slice(1)}:</label>
            <input
              type="number"
              step="0.001"
              value={price}
              onChange={(e) => handleRarityPriceChange(rarity, e.target.value)}
            />
          </div>
        ))}
      </div>

      <button onClick={saveConfigs} className="save-config-button">
        Salvar Configurações de Reparo
      </button>
    </div>
  );
};

export default RepairConfigSettings; 