// adminpanel/MapManagement.js

import React, { useState, useEffect } from 'react';
import { createMap, deleteMap, fetchMaps } from '../../utils/api';
import Cookies from 'js-cookie';
import '../../styles/adminPanel.css';
import ENDPOINTS from '../../utils/endpoints';

const MapManagement = () => {
  const [newMap, setNewMap] = useState({
    name: '',
    description: '',
    raceTime: 0,
    totalCoins: 0,
    bots: 0,
    duration: 0,
    // Novos campos:
    mileage: 0,
    damageIntensity: 'light',

    image: '',
    nfts: [],
    authorized_templates: [],
    boosted_templates: []
  });

  const [maps, setMaps] = useState([]);
  const [availableTemplates, setAvailableTemplates] = useState([]);
  const [schemas, setSchemas] = useState([]);
  const [selectedSchema, setSelectedSchema] = useState('');
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [showTemplateSelector, setShowTemplateSelector] = useState(false);
  const [showBoostTemplateSelector, setShowBoostTemplateSelector] = useState(false);
  const [selectedBoostTemplates, setSelectedBoostTemplates] = useState([]);
  const [boostValues, setBoostValues] = useState({
    boostWin: 0,
    boostDrop: 0,
    boostExp: 0
  });

  useEffect(() => {
    const loadMaps = async () => {
      const token = Cookies.get('authToken');
      const fetchedMaps = await fetchMaps(token);
      setMaps(fetchedMaps);
    };
    loadMaps();
  }, []);

  const fetchTemplates = async () => {
    setLoadingTemplates(true);
    try {
      const response = await fetch(
        `${ENDPOINTS.WAX_API.FETCH_NFTS_ADMINPANEL}?collection_name=${ENDPOINTS.COLLECTION_NAME}`
      );
      const data = await response.json();
      const schemaSet = new Set(data.data.map((nft) => nft.schema.schema_name));
      setSchemas([...schemaSet]);
      setAvailableTemplates(data.data);
    } catch (error) {
      console.error('Error fetching templates:', error);
    } finally {
      setLoadingTemplates(false);
    }
  };

  const handleMapChange = (event) => {
    const { name, value } = event.target;
    setNewMap({ ...newMap, [name]: value });
  };

  const handleMapImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewMap({ ...newMap, image: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddMap = async () => {
    const token = Cookies.get('authToken');
    const mapData = {
      ...newMap,
      mileage: Number(newMap.mileage),
      damageIntensity: newMap.damageIntensity
    };
    await createMap(mapData, token);
    setNewMap({
      name: '',
      description: '',
      raceTime: 0,
      totalCoins: 0,
      bots: 0,
      duration: 0,
      mileage: 0,
      damageIntensity: 'light',
      image: '',
      nfts: [],
      authorized_templates: [],
      boosted_templates: []
    });
    const mapsData = await fetchMaps(token);
    setMaps(mapsData);
    setSelectedBoostTemplates([]);
    setBoostValues({ boostWin: 0, boostDrop: 0, boostExp: 0 });
  };

  const handleDeleteMap = async (mapId) => {
    const token = Cookies.get('authToken');
    try {
      await deleteMap(mapId, token);
      const mapsData = await fetchMaps(token);
      setMaps(mapsData);
    } catch (error) {
      console.error('Failed to delete map:', error);
    }
  };

  const handleSelectTemplate = (template) => {
    const attributes = Object.entries(template.immutable_data).map(([key, value]) => ({
      trait_type: key,
      value: value,
    }));

    setNewMap({
      ...newMap,
      nfts: [...newMap.nfts, {
        template_id: template.template_id,
        img: template.immutable_data.img,
        name: template.immutable_data.name,
        attributes: attributes,
        chance: 0,
        max_drop: 0,
        max_per_race: 0
      }]
    });

    setShowTemplateSelector(false);
  };

  const handleAddAuthorizedTemplate = () => {
    setShowTemplateSelector(true);
  };

  const handleRemoveAuthorizedTemplate = (templateId) => {
    setNewMap({
      ...newMap,
      authorized_templates: newMap.authorized_templates.filter(id => id !== templateId)
    });
  };

  const handleAuthorizedTemplateSelect = (template) => {
    if (!newMap.authorized_templates.includes(template.template_id)) {
      setNewMap({
        ...newMap,
        authorized_templates: [...newMap.authorized_templates, template.template_id]
      });
    }
    setShowTemplateSelector(false);
  };

  const handleAddBoostedTemplates = () => {
    setSelectedBoostTemplates([]);
    setShowBoostTemplateSelector(true);
  };

  const handleBoostTemplateSelect = (template) => {
    if (!selectedBoostTemplates.some(t => t.template_id === template.template_id)) {
      const attributes = Object.entries(template.immutable_data).map(([key, value]) => ({
        trait_type: key,
        value: value,
      }));
      setSelectedBoostTemplates([...selectedBoostTemplates, {
        template_id: template.template_id,
        img: template.immutable_data.img,
        name: template.immutable_data.name,
        attributes: attributes
      }]);
    }
  };

  const handleBoostTemplateDeselect = (template_id) => {
    setSelectedBoostTemplates(selectedBoostTemplates.filter(t => t.template_id !== template_id));
  };

  const handleBoostValuesChange = (e) => {
    const { name, value } = e.target;
    setBoostValues({ ...boostValues, [name]: Number(value) });
  };

  const applyBoostsToSelectedTemplates = () => {
    const { boostWin, boostDrop, boostExp } = boostValues;
    const newBoostedTemplates = selectedBoostTemplates.map(template => ({
      template_id: template.template_id,
      boostWin,
      boostDrop,
      boostExp,
      img: template.img,
      name: template.name,
      attributes: template.attributes
    }));
    setNewMap({
      ...newMap,
      boosted_templates: [...newMap.boosted_templates, ...newBoostedTemplates]
    });
    setSelectedBoostTemplates([]);
    setShowBoostTemplateSelector(false);
    setBoostValues({ boostWin: 0, boostDrop: 0, boostExp: 0 });
  };

  const handleNftChange = (index, field, value) => {
    const updatedNfts = [...newMap.nfts];
    updatedNfts[index][field] = value;
    setNewMap({ ...newMap, nfts: updatedNfts });
  };

  const handleRemoveNft = (index) => {
    const updatedNfts = [...newMap.nfts];
    updatedNfts.splice(index, 1);
    setNewMap({ ...newMap, nfts: updatedNfts });
  };

  const handleSchemaChange = (e) => {
    setSelectedSchema(e.target.value);
  };

  const filteredNfts = availableTemplates.filter(
    (nft) => nft.schema.schema_name === selectedSchema || selectedSchema === ''
  );

  return (
    <div>
      <h3>Map Management</h3>

      <div>
        <label>Map Name:
          <input
            name="name"
            value={newMap.name}
            onChange={handleMapChange}
            placeholder="Map Name"
          />
        </label>
      </div>
      <div>
        <label>Map Description:
          <input
            name="description"
            value={newMap.description}
            onChange={handleMapChange}
            placeholder="Map Description"
          />
        </label>
      </div>
      <div>
        <label>Race Time (in seconds):
          <input
            name="raceTime"
            type="number"
            value={newMap.raceTime}
            onChange={handleMapChange}
            placeholder="Race Time"
          />
        </label>
      </div>
      <div>
        <label>Total Coins:
          <input
            name="totalCoins"
            type="number"
            step="0.01"
            value={newMap.totalCoins}
            onChange={handleMapChange}
            placeholder="Total Coins"
          />
        </label>
      </div>
      <div>
        <label>Bots:
          <input
            name="bots"
            type="number"
            value={newMap.bots}
            onChange={handleMapChange}
            placeholder="Number of Bots"
          />
        </label>
      </div>
      <div>
        <label>Duration (in minutes):
          <input
            name="duration"
            type="number"
            value={newMap.duration}
            onChange={handleMapChange}
            placeholder="Duration"
          />
        </label>
      </div>

      {/* Novos campos: mileage e damageIntensity */}
      <div>
        <label>Mileage (km):
          <input
            name="mileage"
            type="number"
            value={newMap.mileage}
            onChange={handleMapChange}
          />
        </label>
      </div>
      <div>
        <label>Damage Intensity:
          <select
            name="damageIntensity"
            value={newMap.damageIntensity}
            onChange={handleMapChange}
          >
            <option value="light">Light</option>
            <option value="moderate">Moderate</option>
            <option value="extreme">Extreme</option>
          </select>
        </label>
      </div>

      <div>
        <label>Map Image:
          <input
            type="file"
            name="image"
            accept="image/*"
            onChange={handleMapImageChange}
          />
        </label>
        {newMap.image && (
          <img
            src={newMap.image}
            alt="Map Preview"
            style={{ width: '200px', marginTop: '10px' }}
          />
        )}
      </div>

      <button onClick={() => setShowTemplateSelector(true)}>
        Add NFT Template for Map
      </button>

      {showTemplateSelector && (
        <div className="template-selector-modal">
          <button onClick={() => setShowTemplateSelector(false)}>Close</button>
          <button onClick={fetchTemplates} disabled={loadingTemplates}>
            {loadingTemplates ? 'Loading...' : 'Fetch Templates'}
          </button>
          <div>
            <label>
              Select Schema:
              <select value={selectedSchema} onChange={handleSchemaChange}>
                <option value="">All Schemas</option>
                {schemas.map((schema) => (
                  <option key={schema} value={schema}>
                    {schema}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="nft-grid">
            {filteredNfts.map((nft, index) => (
              <div key={index} onClick={() => handleSelectTemplate(nft)}>
                <img
                  src={`https://ipfs.neftyblocks.io/ipfs/${nft.immutable_data.img}`}
                  alt={nft.immutable_data.name}
                  style={{ width: '100px' }}
                />
                <div>{nft.immutable_data.name}</div>
                <div>Schema: {nft.schema.schema_name}</div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div>
        <h4>Authorized Templates</h4>
        <button onClick={handleAddAuthorizedTemplate}>Add Authorized Template</button>
        <div className="authorized-templates-list">
          {newMap.authorized_templates.map((templateId, index) => {
            const template = availableTemplates.find(
              (t) => t.template_id === templateId
            );
            return (
              <div key={index}>
                <span>
                  {template ? template.immutable_data.name : templateId}
                </span>
                <button onClick={() => handleRemoveAuthorizedTemplate(templateId)}>
                  Remove
                </button>
              </div>
            );
          })}
        </div>
      </div>

      <div>
        <h4>Boosted Templates</h4>
        <button onClick={handleAddBoostedTemplates}>Add Boosted Templates</button>

        {showBoostTemplateSelector && (
          <div className="template-selector-modal">
            <button onClick={() => setShowBoostTemplateSelector(false)}>Close</button>
            <button onClick={fetchTemplates} disabled={loadingTemplates}>
              {loadingTemplates ? 'Loading...' : 'Fetch Templates'}
            </button>
            <div>
              <label>
                Select Schema:
                <select value={selectedSchema} onChange={handleSchemaChange}>
                  <option value="">All Schemas</option>
                  {schemas.map((schema) => (
                    <option key={schema} value={schema}>
                      {schema}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div className="nft-grid">
              {filteredNfts.map((template, index) => {
                const isSelected = selectedBoostTemplates.some(
                  (t) => t.template_id === template.template_id
                );
                return (
                  <div key={index}>
                    <input
                      type="checkbox"
                      checked={isSelected}
                      onChange={(e) => {
                        if (e.target.checked) {
                          const attributes = Object.entries(template.immutable_data).map(
                            ([key, value]) => ({
                              trait_type: key,
                              value: value,
                            })
                          );
                          setSelectedBoostTemplates([
                            ...selectedBoostTemplates,
                            {
                              template_id: template.template_id,
                              img: template.immutable_data.img,
                              name: template.immutable_data.name,
                              attributes: attributes,
                            },
                          ]);
                        } else {
                          handleBoostTemplateDeselect(template.template_id);
                        }
                      }}
                    />
                    <img
                      src={`https://ipfs.neftyblocks.io/ipfs/${template.immutable_data.img}`}
                      alt={template.immutable_data.name}
                      style={{ width: '100px' }}
                    />
                    <div>{template.immutable_data.name}</div>
                  </div>
                );
              })}
            </div>
            <div>
              <h5>Set Boost Values:</h5>
              <label>
                Boost Win:
                <input
                  type="number"
                  name="boostWin"
                  value={boostValues.boostWin}
                  onChange={handleBoostValuesChange}
                  step="0.1"
                />
              </label>
              <label>
                Boost Drop:
                <input
                  type="number"
                  name="boostDrop"
                  value={boostValues.boostDrop}
                  onChange={handleBoostValuesChange}
                  step="0.1"
                />
              </label>
              <label>
                Boost Exp:
                <input
                  type="number"
                  name="boostExp"
                  value={boostValues.boostExp}
                  onChange={handleBoostValuesChange}
                  step="0.1"
                />
              </label>
              <button
                onClick={applyBoostsToSelectedTemplates}
                disabled={selectedBoostTemplates.length === 0}
              >
                Apply Boosts to Selected Templates
              </button>
            </div>
          </div>
        )}

        {newMap.boosted_templates.length > 0 && (
          <div className="boosted-templates-list">
            {newMap.boosted_templates.map((boost, index) => {
              return (
                <div key={index}>
                  <span>{boost.name || boost.template_id}</span>
                  <span> Win Boost: {boost.boostWin}</span>
                  <span> Drop Boost: {boost.boostDrop}</span>
                  <span> Exp Boost: {boost.boostExp}</span>
                  <button
                    onClick={() => {
                      const updatedBoosted = [...newMap.boosted_templates];
                      updatedBoosted.splice(index, 1);
                      setNewMap({ ...newMap, boosted_templates: updatedBoosted });
                    }}
                  >
                    Remove
                  </button>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {newMap.nfts.length > 0 && (
        <div className="selected-nfts">
          {newMap.nfts.map((nft, index) => (
            <div key={index}>
              <p>{nft.name}</p>
              <label>
                Chance (%):
                <input
                  type="number"
                  value={nft.chance}
                  onChange={(e) => handleNftChange(index, 'chance', e.target.value)}
                />
              </label>
              <label>
                Max Drop:
                <input
                  type="number"
                  value={nft.max_drop}
                  onChange={(e) => handleNftChange(index, 'max_drop', e.target.value)}
                />
              </label>
              <label>
                Max Per Race:
                <input
                  type="number"
                  value={nft.max_per_race}
                  onChange={(e) => handleNftChange(index, 'max_per_race', e.target.value)}
                />
              </label>
              <img
                src={`https://ipfs.neftyblocks.io/ipfs/${nft.img}`}
                alt={nft.name}
                style={{ width: '100px', marginTop: '10px' }}
              />
              <p>Attributes:</p>
              <ul>
                {nft.attributes && nft.attributes.length > 0 ? (
                  nft.attributes.map((attr, i) => (
                    <li key={i}>
                      {attr.trait_type}: {attr.value}
                    </li>
                  ))
                ) : (
                  <li>No attributes available.</li>
                )}
              </ul>
              <button onClick={() => handleRemoveNft(index)}>Remover NFT</button>
            </div>
          ))}
        </div>
      )}

      <button onClick={handleAddMap}>Add Map</button>

      <ul>
        {maps.map((map) => (
          <li key={map._id}>
            {map.name}
            <button onClick={() => handleDeleteMap(map._id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MapManagement;
