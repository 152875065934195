// src/components/Stake.js

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useAuth } from '../contexts/AuthContext';
import {
    fetchNFTs, checkStake, createStake, getStakeCoins, collectStakeCoins,
    removeStake, fetchStakeConfig, checkCarsInRaceBatch, checkCarInRace,
    getStakeRarityCounts,
} from '../utils/api';
import './Stake.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TailSpin } from 'react-loader-spinner';

const Stake = () => {
    const { userAccount } = useAuth();
    
    // Available rarities list
    const rarityOptions = ['Ultimate', 'Exotic', 'Mythic', 'Legendary', 'Epic', 'Rare', 'Uncommon', 'Common'];

    // States for Wallet Inventory
    const [walletNfts, setWalletNfts] = useState([]);
    const [selectedWalletNfts, setSelectedWalletNfts] = useState([]);
    const [selectedRaritiesWallet, setSelectedRaritiesWallet] = useState([]);
    const [walletPage, setWalletPage] = useState(1);

    // States for Staked NFTs
    const [stakedNfts, setStakedNfts] = useState([]);
    const [stakedNftDetails, setStakedNftDetails] = useState([]);
    const [selectedStakedNfts, setSelectedStakedNfts] = useState([]);
    const [selectedRaritiesStaked, setSelectedRaritiesStaked] = useState([]);
    const [stakedPage, setStakedPage] = useState(1);

    // New State for NFT Counts by Rarity in Stake
    const [stakeRarityCounts, setStakeRarityCounts] = useState({
        ultimate: 0,
        exotic: 0,
        mythic: 0,
        epic: 0,
        legendary: 0,
        rare: 0,
        uncommon: 0,
        common: 0,
    });

    // Other states
    const [stakeCoins, setStakeCoins] = useState(0);
    const [stakeConfig, setStakeConfig] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoadingWallet, setIsLoadingWallet] = useState(false);
    const [isLoadingStaked, setIsLoadingStaked] = useState(false);
    const [isRefreshingWallet, setIsRefreshingWallet] = useState(false);
    const [isRefreshingStaked, setIsRefreshingStaked] = useState(false);
    const [refreshCountWallet, setRefreshCountWallet] = useState(0);
    const [refreshCountStaked, setRefreshCountStaked] = useState(0);
    const [isCollectCooldown, setIsCollectCooldown] = useState(false);
    const [collectCooldownTime, setCollectCooldownTime] = useState(0);
    const [isRefreshCooldownWallet, setIsRefreshCooldownWallet] = useState(false);
    const [refreshCooldownTimeWallet, setRefreshCooldownTimeWallet] = useState(0);
    const [isRefreshCooldownStaked, setIsRefreshCooldownStaked] = useState(false);
    const [refreshCooldownTimeStaked, setRefreshCooldownTimeStaked] = useState(0);

    const itemsPerPage = 50;
    const MAX_REFRESH_CLICKS = 5;
    const REFRESH_COOLDOWN_DURATION = 10; 
    const COINS_COLLECTION_INTERVAL = 12 * 60 * 60; 

    // 1) Load Staked NFTs
    const loadStakedNfts = useCallback(async () => {
        if (!userAccount) return;
        setIsLoadingStaked(true);
        try {
            const allNfts = await fetchNFTs(userAccount);
            const staked = await checkStake(userAccount); 
            setStakedNfts(staked);
            const stakedDetails = allNfts.filter(nft => staked.includes(nft.asset_id));

            let finalStakedNfts = stakedDetails;
            if (selectedRaritiesStaked.length > 0) {
                finalStakedNfts = stakedDetails.filter(nft => 
                    selectedRaritiesStaked.includes(nft.template.immutable_data['Car Rarity'])
                );
            }

            setStakedNftDetails(finalStakedNfts);
            setStakedNfts(finalStakedNfts.map(nft => nft.asset_id));
            await loadStakeRarityCounts();
        } catch (error) {
            console.error('Error loading staked NFTs:', error);
            toast.error('Error loading staked NFTs. Please try again.');
        } finally {
            setIsLoadingStaked(false);
            setIsRefreshingStaked(false);
        }
    }, [userAccount, selectedRaritiesStaked]);

    // 2) Load Wallet NFTs (not staked)
    const loadWalletNfts = useCallback(async () => {
        if (!userAccount) return;
        setIsLoadingWallet(true);
        try {
            const allNfts = await fetchNFTs(userAccount);
            const availableNfts = allNfts.filter(nft => !stakedNfts.includes(nft.asset_id));
            const assetIds = availableNfts.map(nft => nft.asset_id).filter(id => id != null);

            if (assetIds.length === 0) {
                setWalletNfts(availableNfts);
                return;
            }

            const response = await checkCarsInRaceBatch(assetIds);
            let raceResultMap = {};
            response.results.forEach(res => {
                raceResultMap[res.carId] = res.inRace;
            });

            const availableNftsWithRace = availableNfts.map(nft => {
                const assetId = nft.asset_id;
                return {
                    ...nft,
                    inRace: assetId ? (raceResultMap[assetId] || false) : false
                };
            });

            let finalAvailableNfts = availableNftsWithRace;
            if (selectedRaritiesWallet.length > 0) {
                finalAvailableNfts = availableNftsWithRace.filter(nft =>
                    selectedRaritiesWallet.includes(nft.template?.immutable_data?.['Car Rarity'])
                );
            }

            setWalletNfts(finalAvailableNfts);
        } catch (error) {
            console.error('Error loading Wallet NFTs:', error);
            toast.error('Error loading Wallet NFTs. Please try again.');
        } finally {
            setIsLoadingWallet(false);
            setIsRefreshingWallet(false);
        }
    }, [userAccount, selectedRaritiesWallet, stakedNfts]);

    // 3) Load stake coins balance
    const loadStakeCoins = useCallback(async () => {
        if (!userAccount) return;
        try {
            const { stakeCoins: coins, lastCollected } = await getStakeCoins(userAccount);
            setStakeCoins(parseFloat(coins.$numberDecimal ?? coins));

            if (lastCollected) {
                const now = new Date();
                const timeSinceLastCollect = (now - new Date(lastCollected)) / 1000; 
                if (timeSinceLastCollect < COINS_COLLECTION_INTERVAL) {
                    setCollectCooldownTime(COINS_COLLECTION_INTERVAL - timeSinceLastCollect);
                    setIsCollectCooldown(true);
                }
            }
        } catch (error) {
            console.error('Error loading coin balance:', error);
        }
    }, [userAccount]);

    // 4) Load stake configuration
    const loadStakeConfig = useCallback(async () => {
        try {
            const config = await fetchStakeConfig();
            const rewardValuesParsed = {};
            Object.keys(config.rewardValues).forEach((key) => {
                rewardValuesParsed[key] = parseFloat(config.rewardValues[key].$numberDecimal);
            });
            const balanceParsed = parseFloat(config.saldo.$numberDecimal);
            setStakeConfig({
                ...config,
                rewardValues: rewardValuesParsed,
                saldo: balanceParsed,
            });
        } catch (error) {
            console.error('Error loading stake configuration:', error);
            if (userAccount) {
                toast.error('Error loading stake configuration. Please try again.');
            }
        }
    }, [userAccount]);

    // 5) Load NFT Counts by Rarity in Stake
    const loadStakeRarityCounts = useCallback(async () => {
        if (!userAccount) return;
        try {
            const counts = await getStakeRarityCounts();
            setStakeRarityCounts(counts);
        } catch (error) {
            console.error('Error loading stake rarity counts:', error);
            toast.error('Error loading stake rarity counts. Please try again.');
        }
    }, [userAccount]);

    // 6) Check refresh clicks (Wallet)
    const checkRefreshClicksWallet = useCallback(() => {
        if (!userAccount) return;
        const refreshClicks = JSON.parse(localStorage.getItem(`refreshClicksWallet_${userAccount}`)) || [];
        const now = Date.now();
        const validClicks = refreshClicks.filter(clickTime => now - clickTime < 60000);
        localStorage.setItem(`refreshClicksWallet_${userAccount}`, JSON.stringify(validClicks));
        setRefreshCountWallet(validClicks.length);
    }, [userAccount]);

    // 7) Check refresh clicks (Staked)
    const checkRefreshClicksStaked = useCallback(() => {
        if (!userAccount) return;
        const refreshClicks = JSON.parse(localStorage.getItem(`refreshClicksStaked_${userAccount}`)) || [];
        const now = Date.now();
        const validClicks = refreshClicks.filter(clickTime => now - clickTime < 60000);
        localStorage.setItem(`refreshClicksStaked_${userAccount}`, JSON.stringify(validClicks));
        setRefreshCountStaked(validClicks.length);
    }, [userAccount]);

    useEffect(() => {
        if (userAccount) {
            loadStakedNfts();
        }
    }, [userAccount, loadStakedNfts]);

    useEffect(() => {
        if (userAccount) {
            loadWalletNfts();
        }
    }, [userAccount, loadWalletNfts]);

    useEffect(() => {
        if (userAccount) {
            loadStakeCoins();
            loadStakeConfig();
            checkRefreshClicksWallet();
            checkRefreshClicksStaked();
        } else {
            loadStakeConfig();
        }
    }, [userAccount, loadStakeCoins, loadStakeConfig, checkRefreshClicksWallet, checkRefreshClicksStaked]);

    useEffect(() => {
        if (!userAccount) return;
        let collectInterval;
        let refreshIntervalWallet;
        let refreshIntervalStaked;

        if (isCollectCooldown && collectCooldownTime > 0) {
            collectInterval = setInterval(() => {
                setCollectCooldownTime(prevTime => prevTime - 1);
            }, 1000);
        } else if (collectCooldownTime <= 0 && isCollectCooldown) {
            clearInterval(collectInterval);
            setIsCollectCooldown(false);
            setCollectCooldownTime(0);
        }

        if (isRefreshCooldownWallet && refreshCooldownTimeWallet > 0) {
            refreshIntervalWallet = setInterval(() => {
                setRefreshCooldownTimeWallet(prevTime => prevTime - 1);
            }, 1000);
        } else if (refreshCooldownTimeWallet <= 0 && isRefreshCooldownWallet) {
            clearInterval(refreshIntervalWallet);
            setIsRefreshCooldownWallet(false);
            setRefreshCooldownTimeWallet(0);
            setRefreshCountWallet(0);
            localStorage.removeItem(`refreshClicksWallet_${userAccount}`);
        }

        if (isRefreshCooldownStaked && refreshCooldownTimeStaked > 0) {
            refreshIntervalStaked = setInterval(() => {
                setRefreshCooldownTimeStaked(prevTime => prevTime - 1);
            }, 1000);
        } else if (refreshCooldownTimeStaked <= 0 && isRefreshCooldownStaked) {
            clearInterval(refreshIntervalStaked);
            setIsRefreshCooldownStaked(false);
            setRefreshCooldownTimeStaked(0);
            setRefreshCountStaked(0);
            localStorage.removeItem(`refreshClicksStaked_${userAccount}`);
        }

        return () => {
            clearInterval(collectInterval);
            clearInterval(refreshIntervalWallet);
            clearInterval(refreshIntervalStaked);
        };
    }, [
        isCollectCooldown, collectCooldownTime, 
        isRefreshCooldownWallet, refreshCooldownTimeWallet,
        isRefreshCooldownStaked, refreshCooldownTimeStaked,
        userAccount
    ]);

    const handleCollectStakeCoins = async () => {
        if (!userAccount) return;
        try {
            if (stakeCoins > 0) {
                const response = await collectStakeCoins(userAccount);
                if (response.error) {
                    if (response.message.includes("You can collect")) {
                        toast.info(response.message);
                    } else {
                        toast.error(response.message);
                    }
                } else {
                    setStakeCoins(0);
                    toast.success(`Successfully collected PXRS!`);
                    loadStakeCoins();
                }
            } else {
                toast.info('No coins available to collect.');
            }
        } catch (error) {
            console.error('Error collecting stake coins:', error);
        }
    };

    const handleSelectStaked = (nft) => {
        if (!userAccount) return;
        setSelectedStakedNfts(prevState => {
            if (prevState.includes(nft)) {
                return prevState.filter(item => item.asset_id !== nft.asset_id);
            } else {
                return [...prevState, nft];
            }
        });
    };

    const handleSelectWallet = async (nft) => {
        if (!userAccount) return;
        const isInRace = await checkCarInRace(nft.asset_id);
        if (isInRace.inRace) {
            toast.warn('This NFT is in a race and cannot be selected for stake.');
            return;
        }
        if (stakedNfts.includes(nft.asset_id)) return;
        setSelectedWalletNfts(prevState => {
            if (prevState.find(item => item.asset_id === nft.asset_id)) {
                return prevState.filter(item => item.asset_id !== nft.asset_id);
            } else {
                return [...prevState, nft];
            }
        });
    };

    const handleStake = async () => {
        if (!userAccount) return;
        if (isSubmitting) return;
        setIsSubmitting(true);
        try {
            if (selectedWalletNfts.length === 0) {
                toast.error('Please select at least one NFT to stake.');
                setIsSubmitting(false);
                return;
            }
            const carIds = selectedWalletNfts.map(nft => nft.asset_id);
            const batchSize = 25;
            const batches = [];
            for (let i = 0; i < carIds.length; i += batchSize) {
                batches.push(carIds.slice(i, i + batchSize));
            }
            let checkedResults = [];
            const checkTasks = batches.map((batch, batchIndex) => 
                checkCarsInRaceBatch(batch).catch(error => {
                    console.error(`Error checking cars in race for batch ${batchIndex}:`, error);
                    return [];
                })
            );
            const checkResults = await Promise.all(checkTasks);
            checkResults.forEach(batchResults => {
                checkedResults = checkedResults.concat(batchResults);
            });
            const anyInRace = checkedResults.some(result => result.inRace);
            if (anyInRace) {
                toast.error('One or more selected NFTs are in a race and cannot be staked.');
                setIsSubmitting(false);
                return;
            }
            const totalBatches = Math.ceil(selectedWalletNfts.length / batchSize);
            for (let i = 0; i < totalBatches; i++) {
                const batch = selectedWalletNfts.slice(i * batchSize, (i + 1) * batchSize);
                const nftIds = batch.map(nft => nft.asset_id);
                try {
                    await createStake(nftIds, userAccount);
                    toast.success(`${batch.length} NFT(s) staked successfully!`);
                } catch (error) {
                    console.error(`Error processing NFT batch ${i + 1}:`, error.message);
                    toast.error(`Error processing NFT batch ${i + 1}. Some NFTs may not have been staked.`);
                }
            }
            const newlyStakedNfts = selectedWalletNfts.map(nft => nft.asset_id);
            setStakedNftDetails(prev => [...prev, ...selectedWalletNfts]);
            setStakedNfts(prev => [...prev, ...newlyStakedNfts]);
            setWalletNfts(prev => prev.filter(nft => !newlyStakedNfts.includes(nft.asset_id)));
            setSelectedWalletNfts([]);
        } catch (error) {
            console.error('Error staking NFTs:', error);
            toast.error('An error occurred while staking NFTs. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleRemoveStake = async () => {
        if (!userAccount) return;
        if (isSubmitting) return;
        setIsSubmitting(true);
        try {
            if (selectedStakedNfts.length === 0) {
                toast.error('Please select at least one NFT to remove from stake.');
                setIsSubmitting(false);
                return;
            }
            const nftIds = selectedStakedNfts.map(nft => nft.asset_id);
            const isValidStaked = nftIds.every(id => stakedNfts.includes(id));
            if (!isValidStaked) {
                toast.error('One or more selected NFTs are not in stake.');
                setIsSubmitting(false);
                return;
            }
            await removeStake(nftIds);
            toast.success('NFT(s) successfully unstaked!');
            const unstakedNfts = selectedStakedNfts.map(nft => nft.asset_id);
            setWalletNfts(prev => [...prev, ...selectedStakedNfts]);
            setStakedNftDetails(prev => prev.filter(nft => !unstakedNfts.includes(nft.asset_id)));
            setStakedNfts(prev => prev.filter(id => !unstakedNfts.includes(id)));
            setSelectedStakedNfts([]);
        } catch (error) {
            console.error('Error removing NFTs from stake:', error);
            toast.error('An error occurred while unstaking NFTs. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    // Função corrigida: Seleciona apenas NFTs em stake
    const handleSelectAllStakedNfts = () => {
        if (selectedStakedNfts.length === stakedNftDetails.length) {
            setSelectedStakedNfts([]);
        } else {
            setSelectedStakedNfts([...stakedNftDetails]);
        }
    };

    const handleSelectAllNfts = () => {
        const selectableNfts = walletNfts.filter(nft => !nft.inRace);
        if (selectedWalletNfts.length === selectableNfts.length) {
            setSelectedWalletNfts([]);
        } else {
            setSelectedWalletNfts(selectableNfts);
        }
    };

    const handleRefreshWallet = async () => {
        if (!userAccount) return;
        const now = Date.now();
        const refreshClicks = JSON.parse(localStorage.getItem(`refreshClicksWallet_${userAccount}`)) || [];
        if (refreshCountWallet >= MAX_REFRESH_CLICKS) {
            setIsRefreshCooldownWallet(true);
            setRefreshCooldownTimeWallet(REFRESH_COOLDOWN_DURATION);
        } else {
            setIsRefreshingWallet(true);
            await loadWalletNfts();
            const updatedClicks = [...refreshClicks, now];
            localStorage.setItem(`refreshClicksWallet_${userAccount}`, JSON.stringify(updatedClicks));
            setRefreshCountWallet(prev => prev + 1);
        }
    };

    const handleRefreshStaked = async () => {
        if (!userAccount) return;
        const now = Date.now();
        const refreshClicks = JSON.parse(localStorage.getItem(`refreshClicksStaked_${userAccount}`)) || [];
        if (refreshCountStaked >= MAX_REFRESH_CLICKS) {
            setIsRefreshCooldownStaked(true);
            setRefreshCooldownTimeStaked(REFRESH_COOLDOWN_DURATION);
        } else {
            setIsRefreshingStaked(true);
            await loadStakedNfts();
            const updatedClicks = [...refreshClicks, now];
            localStorage.setItem(`refreshClicksStaked_${userAccount}`, JSON.stringify(updatedClicks));
            setRefreshCountStaked(prev => prev + 1);
        }
    };

    const handleRarityFilterChangeWallet = (rarity) => {
        setSelectedRaritiesWallet(prevState => 
            prevState.includes(rarity) ? prevState.filter(r => r !== rarity) : [...prevState, rarity]
        );
    };

    const handleRarityFilterChangeStaked = (rarity) => {
        setSelectedRaritiesStaked(prevState => 
            prevState.includes(rarity) ? prevState.filter(r => r !== rarity) : [...prevState, rarity]
        );
    };

    const filteredWalletNfts = useMemo(() => {
        if (selectedRaritiesWallet.length === 0) return walletNfts;
        return walletNfts.filter(nft => 
            selectedRaritiesWallet.includes(nft.template.immutable_data['Car Rarity'])
        );
    }, [walletNfts, selectedRaritiesWallet]);

    const filteredStakedNfts = useMemo(() => {
        if (selectedRaritiesStaked.length === 0) return stakedNftDetails;
        return stakedNftDetails.filter(nft => 
            selectedRaritiesStaked.includes(nft.template.immutable_data['Car Rarity'])
        );
    }, [stakedNftDetails, selectedRaritiesStaked]);

    const indexOfLastWalletItem = walletPage * itemsPerPage;
    const indexOfFirstWalletItem = indexOfLastWalletItem - itemsPerPage;
    const currentWalletNfts = useMemo(() => {
        return filteredWalletNfts.slice(indexOfFirstWalletItem, indexOfLastWalletItem);
    }, [filteredWalletNfts, indexOfFirstWalletItem, indexOfLastWalletItem]);

    const indexOfLastStakedItem = stakedPage * itemsPerPage;
    const indexOfFirstStakedItem = indexOfLastStakedItem - itemsPerPage;
    const currentStakedNfts = useMemo(() => {
        return filteredStakedNfts.slice(indexOfFirstStakedItem, indexOfLastStakedItem);
    }, [filteredStakedNfts, indexOfFirstStakedItem, indexOfLastStakedItem]);

    const paginateWallet = (pageNumber) => setWalletPage(pageNumber);
    const paginateStaked = (pageNumber) => setStakedPage(pageNumber);

    const formatCooldownTime = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);
        return `${h}h ${m}m ${s}s`;
    };

    return (
        <div className="stake-container">
            <div className="stake-info">
                <h1 className="stake-title">STAKE</h1>
                <div className="gauge-container">
                    <div className="gauge">
                        <div className="needle"></div>
                    </div>
                </div>
                {stakeConfig && (
                    <div className="info-grid">
                        <div className="stake-status highlight">
                            <p>Stake Status: {stakeConfig.status}</p>
                        </div>
                        <div className="max-coins highlight">
                            <p>Stake Balance: {stakeConfig.saldo.toFixed(2)} PXRS</p>
                        </div>
                        <div className="highlight-rarities">
                            <p>Earnings per Rarity (per hour):</p>
                            <ul>
                                {rarityOptions.map(rarity => (
                                    <li key={rarity}>
                                        {rarity}: {stakeConfig.rewardValues[rarity.toLowerCase()].toFixed(2)} coins
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <hr className="separator-line" />
                        <div className="nfts-in-stake highlight">
                            <p>Your NFTs in Stake: {stakedNftDetails.length}</p>
                        </div>
                        <div className="stake-rarity-counts highlight">
                            <div className="rarity-cards">
                                {rarityOptions.map(rarity => {
                                    const lowercaseRarity = rarity.toLowerCase();
                                    return (
                                        <div key={rarity} className="rarity-card">
                                            <div className="rarity-name">{rarity}</div>
                                            <div className="rarity-count">{stakeRarityCounts[lowercaseRarity]}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}
                {userAccount && (
                    <>
                        <h2 className="your-balance highlight">
                            Your Balance: {stakeCoins.toFixed(2)} PXRS
                        </h2>
                        <h4 className="info-collect-stake">
                            You can withdraw once every 12 hours.
                        </h4>
                        <button
                            onClick={handleCollectStakeCoins}
                            className="custom-collect-button"
                            disabled={isCollectCooldown}
                        >
                            {isCollectCooldown
                                ? `COOLDOWN: ${formatCooldownTime(collectCooldownTime)}`
                                : 'COLLECT'
                            }
                        </button>
                    </>
                )}
                {!userAccount && (
                    <p className="highlight">
                        Please log in to perform staking actions.
                    </p>
                )}
            </div>

            {userAccount && (
                <div className="stake-section">
                    <div className="stake-subsection">
                        <h2>Staked NFTs</h2>
                        <div className="buttons-group">
                            <button
                                className={`custom-refresh-button-stake ${!userAccount && 'disabled'}`}
                                onClick={userAccount ? handleRefreshStaked : null}
                                disabled={isRefreshCooldownStaked || !userAccount}
                            >
                                {isRefreshCooldownStaked
                                    ? `Wait... ${refreshCooldownTimeStaked}s`
                                    : 'Refresh NFTs'
                                }
                            </button>
                            <button
                                onClick={userAccount ? handleSelectAllStakedNfts : null}
                                className={`custom-select-all-button ${!userAccount && 'disabled'}`}
                                disabled={stakedNftDetails.length === 0}
                            >
                                {stakedNftDetails.length === 0
                                    ? 'No NFTs'
                                    : (selectedStakedNfts.length > 0 &&
                                       selectedStakedNfts.length === stakedNftDetails.length)
                                        ? 'Deselect All'
                                        : 'Select All'
                                }
                            </button>
                        </div>

                        <div className="rarity-filter">
                            <p>Filter by Rarity:</p>
                            {rarityOptions.map(rarity => (
                                <label key={rarity} className="rarity-option">
                                    <input
                                        type="checkbox"
                                        value={rarity}
                                        checked={selectedRaritiesStaked.includes(rarity)}
                                        onChange={() => handleRarityFilterChangeStaked(rarity)}
                                    />
                                    {rarity}
                                </label>
                            ))}
                        </div>

                        <div className="nft-list-stake">
                            {isLoadingStaked || isRefreshingStaked ? (
                                <div className="nft-list-stake-loading-container">
                                    <div className="nft-list-stake-spinner-wrapper">
                                        <TailSpin height="50" width="50" color="#00ff00" ariaLabel="loading" />
                                    </div>
                                </div>
                            ) : (
                                currentStakedNfts.map((nft) => (
                                    <div
                                        key={nft.asset_id}
                                        className={`nft-item-stake ${selectedStakedNfts.includes(nft) ? 'selected' : ''}`}
                                        onClick={userAccount ? () => handleSelectStaked(nft) : null}
                                    >
                                        {nft.template && nft.template.immutable_data ? (
                                            <>
                                                <img
                                                    src={`https://ipfs.neftyblocks.io/ipfs/${nft.template.immutable_data.img}`}
                                                    alt={nft.template.immutable_data.name}
                                                    className="nft-image-stake"
                                                />
                                                <div className="nft-name-stake">{nft.template.immutable_data.name}</div>
                                                <div className="nft-id-stake">ID: {nft.asset_id}</div>
                                                <div className="nft-rarity-stake">{nft.template.immutable_data['Car Rarity']}</div>
                                                <p className="in-stake-text-stake">Staked</p>
                                            </>
                                        ) : (
                                            <div className="nft-error">NFT data not available</div>
                                        )}
                                    </div>
                                ))
                            )}
                        </div>

                        <div className="pagination">
                            {[...Array(Math.ceil(filteredStakedNfts.length / itemsPerPage)).keys()].map(number => (
                                <button
                                    key={number}
                                    onClick={() => paginateStaked(number + 1)}
                                    className={`custom-pagination-button ${stakedPage === number + 1 ? 'active' : ''}`}
                                >
                                    {number + 1}
                                </button>
                            ))}
                        </div>

                        {selectedStakedNfts.length > 0 && (
                            <button
                                onClick={handleRemoveStake}
                                className="custom-remove-stake-button fixed-bottom"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Removing...' : 'Remove NFTs'}
                            </button>
                        )}
                    </div>

                    <div className="stake-subsection">
                        <h2>Wallet Inventory</h2>
                        <div className="buttons-group">
                            <button
                                className={`custom-refresh-button-stake ${!userAccount && 'disabled'}`}
                                onClick={userAccount ? handleRefreshWallet : null}
                                disabled={isRefreshCooldownWallet || !userAccount}
                            >
                                {isRefreshCooldownWallet
                                    ? `Wait... ${refreshCooldownTimeWallet}s`
                                    : 'Refresh NFTs'
                                }
                            </button>
                            <button
                                onClick={userAccount ? handleSelectAllNfts : null}
                                className={`custom-select-all-button ${!userAccount && 'disabled'}`}
                                disabled={walletNfts.length === 0}
                            >
                                {walletNfts.length === 0
                                    ? 'No NFTs'
                                    : (selectedWalletNfts.length > 0 &&
                                       selectedWalletNfts.length === walletNfts.filter(nft => !nft.inRace).length)
                                        ? 'Deselect All'
                                        : 'Select All'
                                }
                            </button>
                        </div>

                        <div className="rarity-filter">
                            <p>Filter by Rarity:</p>
                            {rarityOptions.map(rarity => (
                                <label key={rarity} className="rarity-option">
                                    <input
                                        type="checkbox"
                                        value={rarity}
                                        checked={selectedRaritiesWallet.includes(rarity)}
                                        onChange={() => handleRarityFilterChangeWallet(rarity)}
                                    />
                                    {rarity}
                                </label>
                            ))}
                        </div>

                        <div className="nft-list-stake">
                            {isLoadingWallet || isRefreshingWallet ? (
                                <div className="nft-list-stake-loading-container">
                                    <div className="nft-list-stake-spinner-wrapper">
                                        <TailSpin height="50" width="50" color="#00ff00" ariaLabel="loading" />
                                    </div>
                                </div>
                            ) : (
                                currentWalletNfts.map((nft) => (
                                    <div
                                        key={nft.asset_id}
                                        className={`nft-item-stake ${
                                            selectedWalletNfts.some(item => item.asset_id === nft.asset_id) ? 'selected' : ''
                                        } ${stakedNfts.includes(nft.asset_id) ? 'in-stake' : ''} ${nft.inRace ? 'in-race' : ''}`}
                                        onClick={nft.inRace ? null : userAccount ? () => handleSelectWallet(nft) : null}
                                    >
                                        {nft.template && nft.template.immutable_data ? (
                                            <>
                                                <img
                                                    src={`https://ipfs.neftyblocks.io/ipfs/${nft.template.immutable_data.img}`}
                                                    alt={nft.template.immutable_data.name}
                                                    className="nft-image-stake"
                                                />
                                                <div className="nft-name-stake">{nft.template.immutable_data.name}</div>
                                                <div className="nft-id-stake">ID: {nft.asset_id}</div>
                                                <div className="nft-rarity-stake">{nft.template.immutable_data['Car Rarity']}</div>
                                                {nft.inRace && <p className="in-race-text-stake">Racing</p>}
                                                {stakedNfts.includes(nft.asset_id) && <p className="in-stake-text-stake">Staked</p>}
                                            </>
                                        ) : (
                                            <div className="nft-error">NFT data not available</div>
                                        )}
                                    </div>
                                ))
                            )}
                        </div>

                        <div className="pagination">
                            {[...Array(Math.ceil(filteredWalletNfts.length / itemsPerPage)).keys()].map(number => (
                                <button
                                    key={number}
                                    onClick={() => paginateWallet(number + 1)}
                                    className={`custom-pagination-button ${walletPage === number + 1 ? 'active' : ''}`}
                                >
                                    {number + 1}
                                </button>
                            ))}
                        </div>

                        {selectedWalletNfts.length > 0 && (
                            <button
                                onClick={handleStake}
                                disabled={isSubmitting}
                                className={`custom-stake-button fixed-bottom ${isSubmitting ? 'disabled' : ''}`}
                            >
                                {isSubmitting ? 'Staking...' : 'Stake NFTs'}
                            </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Stake;
