import React from 'react';

const GameSidebar = ({
  setShowRewards,
  handleFetchRewards,
  setShowOngoingRaces,
  handleFetchOngoingRaces,
  setShowOficina,
}) => {
  return (
    <div className="sidebar visible">
      <div className="sidebar-content">
        <button
          className="icon-button rewards-button"
          onClick={() => {
            setShowRewards(true);
            handleFetchRewards();
          }}
        >
          <img src={require('../assets/rewards-icon.png')} alt="Rewards Icon" />
        </button>

        <button
          className="icon-button ongoing-races-button"
          onClick={() => {
            setShowOngoingRaces(true);
            handleFetchOngoingRaces();
          }}
        >
          <img src={require('../assets/ongoing-races-icon.png')} alt="Ongoing Races Icon" />
        </button>

        <button
          className="icon-button workshop-button"
          onClick={() => setShowOficina(true)}
        >
          <img src={require('../assets/workshop-icon.png')} alt="Workshop Icon" />
        </button>

        <button className="icon-button multiplayer-button">
          <img src={require('../assets/multiplayer-icon.png')} alt="Multiplayer Icon" />
        </button>
      </div>
    </div>
  );
};

export default GameSidebar; 