// src/components/Home.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import './Home.css';
import logo from '../assets/logo.png'; // Importing the logo

const Home = () => {
  const navigate = useNavigate();
  const [news, setNews] = useState([]);
  const [selectedNews, setSelectedNews] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [hideStartupNews, setHideStartupNews] = useState(
    localStorage.getItem('hideStartupNews') === 'true'
  );
  const [serverStatus, setServerStatus] = useState({ status: 'checking', message: 'Checking server status...' });

  const newsPerPage = 3;
  const totalPages = Math.ceil(news.length / newsPerPage);

  useEffect(() => {
    checkServerStatus();
    const statusInterval = setInterval(checkServerStatus, 30000); // Check every 30 seconds
    return () => clearInterval(statusInterval);
  }, []);

  const checkServerStatus = async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/health`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        timeout: 5000 // 5 segundos de timeout
      });
      
      if (response.ok) {
        setServerStatus({ 
          status: 'online',
          message: '🟢 SERVER ONLINE - The track is open!'
        });
      } else {
        setServerStatus({ 
          status: 'maintenance',
          message: '🟡 SERVER MAINTENANCE'
        });
      }
    } catch (error) {
      setServerStatus({ 
        status: 'offline',
        message: '🔴 SERVER OFFLINE: Pit stop for maintenance!'
      });
    }
  };

  useEffect(() => {
    loadNews();
  }, []);

  useEffect(() => {
    const loadFeaturedNews = async () => {
      try {
        if (!hideStartupNews) {
          const response = await fetch(`${config.apiBaseUrl}/api/news/featured`);
          const data = await response.json();
          if (data.success && data.news) {
            setSelectedNews(data.news);
          }
        }
      } catch (error) {
        console.error('Error loading featured news:', error);
      }
    };

    loadFeaturedNews();
  }, [hideStartupNews]);

  const loadNews = async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/news/active`);
      const data = await response.json();
      if (data.success) {
        setNews(data.news);
      }
    } catch (error) {
      console.error('Error loading news:', error);
    }
  };

  const handlePlayNowClick = () => {
    navigate('/homegame');
  };

  const handleNewsClick = async (newsId) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/news/${newsId}`);
      const data = await response.json();
      if (data.success) {
        setSelectedNews(data.news);
      }
    } catch (error) {
      console.error('Error loading news:', error);
    }
  };

  const handleHideStartupNews = (e) => {
    const checked = e.target.checked;
    localStorage.setItem('hideStartupNews', checked.toString());
    setHideStartupNews(checked);
    if (checked) {
      setSelectedNews(null);
    }
  };

  const handleCloseModal = () => {
    setSelectedNews(null);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const nextPage = () => {
    setCurrentPage((prev) => (prev + 1) % totalPages);
  };

  const prevPage = () => {
    setCurrentPage((prev) => (prev - 1 + totalPages) % totalPages);
  };

  const getCurrentNews = () => {
    const start = currentPage * newsPerPage;
    const currentNews = news.slice(start, start + newsPerPage);
    return currentNews;
  };

  const getNewsGridClassName = () => {
    const currentNews = getCurrentNews();
    if (currentNews.length === 1) return 'news-grid single-news';
    if (currentNews.length === 2) return 'news-grid two-news';
    return 'news-grid';
  };

  return (
    <div className="home-layout">
      <div className="home-content">
        <div className="center-column" style={{ justifyContent: 'flex-start', paddingTop: '30px' }}>
          <div className={`server-status-indicator ${serverStatus.status === 'online' ? 'status-online' : serverStatus.status === 'offline' ? 'status-offline' : 'status-maintenance'}`}>
            {serverStatus.message}
          </div>
          <div className="home-main-content">
            {/* Wrapping the logo and button in a div */}
            <div className="logo-home-container">
              <img src={logo} alt="Pixel Races Logo" className="logo-home-image" />
              <button className="play-button" onClick={handlePlayNowClick}>
                Play Now!
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="news-section">
        <h3>Latest News</h3>
        <div className="news-carousel-container">
          <button 
            className="carousel-button prev" 
            onClick={prevPage}
            disabled={currentPage === 0}
          >
            ❮
          </button>
          
          <div className={getNewsGridClassName()}>
            {getCurrentNews().map((item) => (
              <div key={item._id} className="news-item" onClick={() => handleNewsClick(item._id)}>
                {item.featured && <span className="featured-badge">Featured</span>}
                <img src={item.imageUrl} alt={item.title} />
                <div className="news-info">
                  <h4>{item.title}</h4>
                  <p className="news-date">Published on: {formatDate(item.createdAt)}</p>
                </div>
              </div>
            ))}
          </div>

          <button 
            className="carousel-button next" 
            onClick={nextPage}
            disabled={currentPage === totalPages - 1}
          >
            ❯
          </button>
        </div>
      </div>

      {selectedNews && (
        <div className="news-modal" onClick={handleCloseModal}>
          <div className="news-modal-content" onClick={e => e.stopPropagation()}>
            <button className="close-button" onClick={handleCloseModal}>✕</button>
            <h2>{selectedNews.title}</h2>
            <img src={selectedNews.imageUrl} alt={selectedNews.title} />
            <div className="news-content">
              <p>{selectedNews.content}</p>
              <p className="news-date">Published on: {formatDate(selectedNews.createdAt)}</p>
              {selectedNews.featured && (
                <div className="startup-message-settings">
                  <label>
                    <input
                      type="checkbox"
                      checked={hideStartupNews}
                      onChange={handleHideStartupNews}
                    />
                    Don't show startup messages anymore
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
