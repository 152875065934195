import React, { useState, useEffect } from 'react';
import { 
  getActiveMechanic, 
  getMechanicConfig, 
  hireMechanic, 
  fireMechanic, 
  updateMechanicSettings,
  addCarToMechanic,
  removeCarFromMechanic,
  calculateMechanicCost,
  extendMechanicContract,
  transferPXRS,
  registerTransaction,
  checkRepairProgress
} from '../utils/api';
import { useAuth } from '../contexts/AuthContext';
import '../styles/mechanicSection.css';
import Notification from './Notification';
import mechanic1 from '../assets/mech/mech1.png';
import mechanic2 from '../assets/mech/mech2.png';
import mechanic3 from '../assets/mech/mech3.png';
import { useNavigate } from 'react-router-dom';

const MECHANIC_TYPES = {
  novice: {
    title: 'Novice Mechanic',
    description: 'A beginner mechanic with basic repair skills.',
    image: mechanic1,
    features: [
      { text: 'Repair up to 3 cars simultaneously', icon: '✓' },
      { text: '5 minutes per damage point', icon: '✓' }
    ]
  },
  intermediate: {
    title: 'Pro Mechanic',
    description: 'An experienced mechanic with advanced skills.',
    image: mechanic2,
    features: [
      { text: 'Repair up to 5 cars simultaneously', icon: '✓' },
      { text: '3 minutes per damage point', icon: '✓' },
      { text: 'Basic Auto Repair (by rarity)', icon: '✓' }
    ]
  },
  expert: {
    title: 'Expert Mechanic',
    description: 'A master mechanic with exceptional skills.',
    image: mechanic3,
    features: [
      { text: 'Repair up to 5 cars simultaneously', icon: '✓' },
      { text: '2 minutes per damage point', icon: '✓' },
      { text: 'Full Auto Repair (rarity + status)', icon: '✓' }
    ]
  }
};

const MechanicSection = ({ userAccount, onError }) => {
  const { session, loginWharfKit } = useAuth();
  const [activeMechanic, setActiveMechanic] = useState(null);
  const [mechanicConfig, setMechanicConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedDuration, setSelectedDuration] = useState(1);
  const [hiring, setHiring] = useState(false);
  const [showHireMechanic, setShowHireMechanic] = useState(false);
  const [showMechanicContent, setShowMechanicContent] = useState(true);
  const [notification, setNotification] = useState({ show: false, message: '' });
  const [progressTick, setProgressTick] = useState(0);
  const [extensionDays, setExtensionDays] = useState(1);
  const [showExtensionSlider, setShowExtensionSlider] = useState(false);
  const [error, setError] = useState(null);
  const [timeLeft, setTimeLeft] = useState({});
  const [removeTimers, setRemoveTimers] = useState({});
  const [showFireConfirm, setShowFireConfirm] = useState(false);
  const [selectedMechanic, setSelectedMechanic] = useState(null);
  const [contractTimeLeft, setContractTimeLeft] = useState('');
  const [showAutoRepairSettings, setShowAutoRepairSettings] = useState(false);
  const [autoRepairStatus, setAutoRepairStatus] = useState(null);
  const [showExtendContract, setShowExtendContract] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (userAccount) {
      loadMechanicData();
    }
  }, [userAccount]);

  useEffect(() => {
    let interval;
    if (activeMechanic?.carsInRepair?.length > 0) {
      // Atualizar a cada 5 segundos quando houver carros em reparo
      interval = setInterval(loadMechanicData, 5000);
    } else {
      // Atualizar a cada 30 segundos quando não houver carros em reparo
      interval = setInterval(loadMechanicData, 30000);
    }
    return () => clearInterval(interval);
  }, [activeMechanic?.carsInRepair?.length]);

  useEffect(() => {
    // Update progress every second
    const timer = setInterval(() => {
      setProgressTick(prev => prev + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const updatedTimeLeft = {};
      
      activeMechanic?.carsInRepair.forEach(car => {
        const end = new Date(car.estimatedEndTime);
        const diff = end - now;
        
        if (diff > 0) {
          const hours = Math.floor(diff / (1000 * 60 * 60));
          const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((diff % (1000 * 60)) / 1000);
          
          updatedTimeLeft[car.carId] = `${hours}h ${minutes}m ${seconds}s`;
        } else {
          updatedTimeLeft[car.carId] = 'Completed';
        }
      });
      
      setTimeLeft(updatedTimeLeft);
    }, 1000);

    return () => clearInterval(timer);
  }, [activeMechanic]);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const updatedTimers = {};
      
      activeMechanic?.carsInRepair.forEach(car => {
        const startTime = new Date(car.startRepairTime);
        const minimumTime = 2 * 60 * 1000; // 2 minutes
        const timeElapsed = now - startTime;
        
        if (timeElapsed < minimumTime) {
          const remainingSeconds = Math.ceil((minimumTime - timeElapsed) / 1000);
          updatedTimers[car.carId] = remainingSeconds;
        } else {
          updatedTimers[car.carId] = 0;
        }
      });
      
      setRemoveTimers(updatedTimers);
    }, 1000);

    return () => clearInterval(timer);
  }, [activeMechanic]);

  useEffect(() => {
    if (activeMechanic) {
      const timer = setInterval(() => {
        const now = new Date();
        const end = new Date(activeMechanic.endDate);
        const diff = end - now;
        
        if (diff > 0) {
          const days = Math.floor(diff / (1000 * 60 * 60 * 24));
          const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((diff % (1000 * 60)) / 1000);
          
          setContractTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
        } else {
          setContractTimeLeft('Contract Expired');
          clearInterval(timer);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [activeMechanic]);

  // Função para verificar o progresso do reparo
  const checkProgress = async () => {
    try {
      const result = await checkRepairProgress();
      
      if (result.completedCars.length > 0) {
        showNotification(`${result.completedCars.length} cars completed repair!`);
      }
      
      // Update mechanic data to reflect changes
      await loadMechanicData();
    } catch (error) {
      console.error('Error checking repair progress:', error);
    }
  };

  // Configurar verificação periódica
  useEffect(() => {
    if (activeMechanic && activeMechanic.carsInRepair.length > 0) {
      const interval = setInterval(checkProgress, 30000); // Check every 30 seconds
      return () => clearInterval(interval);
    }
  }, [activeMechanic]);

  const loadMechanicData = async () => {
    try {
      if (!userAccount) {
        console.log('No user account provided');
        return;
      }

      const [mechanic, configResponse] = await Promise.all([
        getActiveMechanic(userAccount),
        getMechanicConfig()
      ]);

      setActiveMechanic(mechanic);
      if (configResponse && configResponse.success && configResponse.mechanicConfig) {
        setMechanicConfig(configResponse.mechanicConfig);
      }
    } catch (error) {
      console.error('Error loading mechanic data:', error);
      showNotification(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleHireMechanic = async (mechanicType, duration) => {
    try {
      if (!session) {
        showNotification('Please connect your wallet first');
        return;
      }

      setHiring(true);
      showNotification('Calculating cost...');

      // Buscar o custo do servidor
      const costResponse = await calculateMechanicCost(mechanicType, duration);
      const cost = parseFloat(costResponse.cost);
      
      const burnAmount = (cost / 2).toFixed(8);
      const contractAmount = (cost / 2).toFixed(8);

      const transaction = {
        actions: [{
          account: 'pixelracesmg',
          name: 'transfer',
          authorization: [{
            actor: session.actor,
            permission: 'active',
          }],
          data: {
            from: session.actor,
            to: 'eosio.null',
            quantity: `${burnAmount} PXRS`,
            memo: `Burn PXRS - Mechanic ${mechanicType} for ${duration} days`,
          },
        },
        {
          account: 'pixelracesmg',
          name: 'transfer',
          authorization: [{
            actor: session.actor,
            permission: 'active',
          }],
          data: {
            from: session.actor,
            to: 'pixelracesmg',
            quantity: `${contractAmount} PXRS`,
            memo: `Hire ${mechanicType} mechanic for ${duration} days`,
          },
        }]
      };

      showNotification('Please confirm the transaction in your wallet...');
      const result = await session.transact(transaction, { broadcast: true });

      if (!result?.response?.transaction_id) {
        throw new Error('Failed to broadcast transaction');
      }

      showNotification('Please wait while we process your request...');
      const response = await hireMechanic(
        mechanicType, 
        duration, 
        result.response.transaction_id,
        cost.toFixed(8)
      );

      if (response.success) {
        showNotification('Mechanic hired successfully!');
        await loadMechanicData(); // Reload mechanic data
      } else {
        showNotification('Failed to hire mechanic. Please try again.');
      }

    } catch (error) {
      console.error('Error hiring mechanic:', error);
      showNotification('Failed to hire mechanic. Please try again.');
    } finally {
      setHiring(false);
    }
  };

  const calculateProgress = (car) => {
    const now = new Date();
    const start = new Date(car.startRepairTime);
    const end = new Date(car.estimatedEndTime);
    const total = end - start;
    const elapsed = now - start;
    const progress = Math.min(100, (elapsed / total) * 100);
    return progress;
  };

  const handleFireMechanic = async () => {
    if (activeMechanic?.type) {
      setSelectedMechanic(activeMechanic);
      setShowFireConfirm(true);
    } else {
      console.error("Mechanic data missing", activeMechanic);
    }
  };

  const handleAutoRepairToggle = async () => {
    try {
      // Update local state immediately for visual feedback
      setActiveMechanic(prev => ({
        ...prev,
        autoRepairEnabled: !prev.autoRepairEnabled
      }));

      const settings = {
        autoRepairEnabled: !activeMechanic.autoRepairEnabled,
        autoRepairRarities: activeMechanic.autoRepairRarities || {},
        autoRepairStatuses: activeMechanic.autoRepairStatuses || {}
      };

      console.log('Enviando configurações de auto repair:', settings); // Debug

      const updated = await updateMechanicSettings(userAccount, settings);

      if (updated.success) {
        // Update state with data returned from server
        setActiveMechanic(prev => ({
          ...prev,
          ...updated.mechanic
        }));
        showNotification('Auto repair successfully updated!');
      } else {
        // Se falhar, reverter o estado local
        setActiveMechanic(prev => ({
          ...prev,
          autoRepairEnabled: !prev.autoRepairEnabled
        }));
        throw new Error(updated.message || 'Failed to update auto repair');
      }
    } catch (error) {
      console.error('Error updating auto repair:', error);
      showNotification(error.message || 'Error updating auto repair');
      // State already reverted in case of error
    }
  };

  const handleRarityToggle = async (rarity) => {
    try {
      // Update local state immediately for visual feedback
      setActiveMechanic(prev => ({
        ...prev,
        autoRepairRarities: {
          ...prev.autoRepairRarities,
          [rarity]: !prev.autoRepairRarities[rarity]
        }
      }));

      const updatedRarities = {
        ...activeMechanic.autoRepairRarities,
        [rarity]: !activeMechanic.autoRepairRarities[rarity]
      };
      
      const settings = {
        autoRepairEnabled: activeMechanic.autoRepairEnabled,
        autoRepairRarities: updatedRarities,
        autoRepairStatuses: activeMechanic.autoRepairStatuses || {}
      };

      console.log('Enviando configurações de raridade:', settings); // Debug
      
      const updated = await updateMechanicSettings(userAccount, settings);

      if (updated.success) {
        // Update state with data returned from server
        setActiveMechanic(prev => ({
          ...prev,
          ...updated.mechanic
        }));
        showNotification(`Auto repair for cars ${rarity} updated!`);
      } else {
        // Se falhar, reverter o estado local
        setActiveMechanic(prev => ({
          ...prev,
          autoRepairRarities: {
            ...prev.autoRepairRarities,
            [rarity]: !prev.autoRepairRarities[rarity]
          }
        }));
        throw new Error(updated.message || 'Failed to update rarity settings');
      }
    } catch (error) {
      console.error('Error updating rarity:', error);
      showNotification(error.message || 'Failed to update rarity settings');
      // State already reverted in case of error
    }
  };

  const handleStatusToggle = async (status) => {
    try {
      // Update local state immediately for visual feedback
      setActiveMechanic(prev => ({
        ...prev,
        autoRepairStatuses: {
          ...prev.autoRepairStatuses,
          [status]: !prev.autoRepairStatuses[status]
        }
      }));

      const updatedStatuses = {
        ...activeMechanic.autoRepairStatuses,
        [status]: !activeMechanic.autoRepairStatuses[status]
      };
      
      const updated = await updateMechanicSettings(userAccount, {
        autoRepairStatuses: updatedStatuses
      });

      if (updated.success) {
        setActiveMechanic(updated.mechanic);
        showNotification(`Auto repair for status ${status.replace('_', ' ')} updated!`);
      } else {
        throw new Error(updated.message || 'Failed to update status settings');
      }
    } catch (error) {
      console.error('Error updating status:', error);
      showNotification(error.message || 'Failed to update status settings');
      // State already reverted in case of error
      setActiveMechanic(prev => ({
        ...prev,
        autoRepairStatuses: {
          ...prev.autoRepairStatuses,
          [status]: !prev.autoRepairStatuses[status]
        }
      }));
    }
  };

  const showNotification = (message) => {
    setNotification({ show: true, message });
    setTimeout(() => setNotification(prev => ({ ...prev, show: false })), 3000);
  };

  const handleRefresh = async () => {
    await loadMechanicData();
  };

  const toggleMechanicContent = () => {
    setShowMechanicContent(!showMechanicContent);
  };

  const handleAddToMechanic = async (car) => {
    try {
      if (!activeMechanic) {
        showNotification('You need to hire a mechanic first!');
        return;
      }

      await addCarToMechanic(car.carId, userAccount);
      showNotification('Car sent to mechanic successfully!');
      // Atualizar imediatamente após adicionar o carro
      await loadMechanicData();
      
      // Iniciar atualizações frequentes por 15 segundos
      let count = 0;
      const quickUpdateInterval = setInterval(async () => {
        await loadMechanicData();
        count++;
        if (count >= 3) { // 3 atualizações em 15 segundos
          clearInterval(quickUpdateInterval);
        }
      }, 5000);

    } catch (error) {
      showNotification(error.message);
    }
  };

  const formatDate = (date) => {
    const d = new Date(date);
    return `${d.toLocaleDateString()} ${d.getHours()}:${String(d.getMinutes()).padStart(2, '0')}`;
  };

  const handleExtendContract = async (days) => {
    try {
      if (!session) {
        showNotification('Please connect your wallet first');
        return;
      }

      const startDate = new Date(activeMechanic.startDate);
      const currentEndDate = new Date(activeMechanic.endDate);
      
      // Calcular quantos dias totais já foram contratados
      const totalDaysContracted = Math.ceil((currentEndDate - startDate) / (1000 * 60 * 60 * 24));
      
      // Verificar se a extensão ultrapassa o limite de 30 dias
      if (totalDaysContracted + days > 30) {
        showNotification('Maximum contract duration is 30 days!');
        return;
      }

      // Calcular custo da extensão
      const costResponse = await calculateMechanicCost(activeMechanic.type, days);
      const cost = parseFloat(costResponse.cost);
      
      const burnAmount = (cost / 2).toFixed(8);
      const contractAmount = (cost / 2).toFixed(8);

      // Criar transação WAX
      const transaction = {
        actions: [{
          account: 'pixelracesmg',
          name: 'transfer',
          authorization: [{
            actor: session.actor,
            permission: 'active',
          }],
          data: {
            from: session.actor,
            to: 'eosio.null',
            quantity: `${burnAmount} PXRS`,
            memo: `Burn PXRS - Extend ${activeMechanic.type} mechanic contract for ${days} days`,
          },
        },
        {
          account: 'pixelracesmg',
          name: 'transfer',
          authorization: [{
            actor: session.actor,
            permission: 'active',
          }],
          data: {
            from: session.actor,
            to: 'pixelracesmg',
            quantity: `${contractAmount} PXRS`,
            memo: `Extend ${activeMechanic.type} mechanic contract for ${days} days`,
          },
        }]
      };

      showNotification('Please confirm the transaction in your wallet...');
      const result = await session.transact(transaction, { broadcast: true });

      if (!result?.response?.transaction_id) {
        throw new Error('Failed to broadcast transaction');
      }

      showNotification('Please wait while we process your request...');
      const response = await extendMechanicContract(
        days, 
        result.response.transaction_id,
        cost.toFixed(8)
      );
      
      if (response.success) {
        await loadMechanicData();
        showNotification('Contract extended successfully!');
        setExtensionDays(0); // Reset slider after extension
        setShowExtensionSlider(false);
      } else {
        throw new Error(response.message || 'Failed to extend contract');
      }
    } catch (error) {
      console.error('Error extending contract:', error);
      showNotification(error.message || 'Failed to extend contract');
    }
  };

  const calculateAvailableDays = () => {
    if (!activeMechanic) return 0;
    const startDate = new Date(activeMechanic.startDate);
    const currentEndDate = new Date(activeMechanic.endDate);
    
    // Calcular quantos dias totais já foram contratados
    const totalDaysContracted = Math.ceil((currentEndDate - startDate) / (1000 * 60 * 60 * 24));
    
    // Só pode ter no máximo 30 dias de contrato
    const remainingDays = 30 - totalDaysContracted;
    
    return Math.max(0, remainingDays);
  };

  const calculateExtensionCost = (days) => {
    if (!activeMechanic || days === 0 || !mechanicConfig) return '0.00000000';
    
    // Use the daily cost from mechanic configuration
    const mechanicType = `${activeMechanic.type}Mechanic`;
    const dailyCost = mechanicConfig[mechanicType]?.costPerDay || 0;
    
    return (dailyCost * days).toFixed(8);
  };

  const canUseAutoRepair = (mechanicType) => {
    return mechanicType !== 'novice';
  };

  const canUseStatusFilter = (mechanicType) => {
    return mechanicType === 'expert';
  };

  const handleRemoveCar = async (carId) => {
    try {
      setLoading(true);
      await removeCarFromMechanic(carId);
      // Update car list
      await loadMechanicData();
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const formatRemoveTimer = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const confirmFireMechanic = async () => {
    try {
      const response = await fireMechanic(userAccount);
      
      if (response.success) {
        showNotification('Mechanic fired successfully!');
        setShowFireConfirm(false);
        await loadMechanicData();
      } else {
        throw new Error(response.message || 'Failed to fire mechanic');
      }
    } catch (error) {
      console.error('Error firing mechanic:', error);
      showNotification(error.message || 'Failed to fire mechanic');
    }
  };

  if (loading) return <div className="mechanic-loading">Loading mechanic data...</div>;

  return (
    <div className="mechanic-section">
      <div className="mechanic-header">
        <h3>
          <span className="emoji">🔧</span>
          Mechanics
        </h3>
        <div className="header-controls">
          <button 
            className="toggle-hire-btn"
            onClick={toggleMechanicContent}
          >
            [ {showMechanicContent ? '-' : '+'} ] Hide Mechanics
          </button>
          <button 
            className="mechanic-refresh-btn" 
            onClick={handleRefresh}
            title="Refresh"
          >
            ⟳
          </button>
        </div>
      </div>

      {showMechanicContent && (
        <>
          {activeMechanic ? (
            <div className="active-mechanic">
              <div className="section-title">
                <h4>🏁 Your Active Mechanic</h4>
              </div>
              <div className="mechanic-info">
                <div className="mechanic-profile">
                  <img 
                    src={MECHANIC_TYPES[activeMechanic.type].image} 
                    alt={MECHANIC_TYPES[activeMechanic.type].title}
                    className="mechanic-avatar"
                  />
                  <div className="mechanic-profile-info">
                    <h3>{MECHANIC_TYPES[activeMechanic.type].title}</h3>
                    <p>{MECHANIC_TYPES[activeMechanic.type].description}</p>
                  </div>
                </div>

                <div className="mechanic-controls">
                  <div className="mechanic-buttons">
                    <div className="mechanic-action-buttons">
                      <button className="fire-mechanic-btn" onClick={handleFireMechanic}>
                        Fire Mechanic
                      </button>
                      <button 
                        className="extend-contract-btn" 
                        onClick={() => setShowExtensionSlider(!showExtensionSlider)}
                      >
                        Extend Contract
                      </button>
                    </div>
                  </div>
                </div>

                {showExtensionSlider && (
                  <div className="contract-extension">
                    <div className="extension-slider">
                      <input
                        type="range"
                        min="0"
                        max={calculateAvailableDays()}
                        value={extensionDays}
                        onChange={(e) => setExtensionDays(Number(e.target.value))}
                        className="days-slider"
                      />
                      <div className="extension-info">
                        <span>+{extensionDays} days</span>
                        <span className="extension-cost">
                          {calculateExtensionCost(extensionDays)} PXRS
                        </span>
                      </div>
                    </div>
                    <button
                      className="confirm-extend-button"
                      onClick={() => {
                        handleExtendContract(extensionDays);
                        setShowExtensionSlider(false);
                      }}
                      disabled={extensionDays === 0}
                    >
                      Confirm Extension
                    </button>
                  </div>
                )}

                <div className="mechanic-stats">
                  <div className="stat-card">
                    <span className="stat-icon">📅</span>
                    <div className="stat-info">
                      <div className="stat-label">Contract Expires In</div>
                      <div className="stat-value">
                        {contractTimeLeft}
                      </div>
                    </div>
                  </div>
                  <div className="stat-card">
                    <span className="stat-icon">🚗</span>
                    <div className="stat-info">
                      <div className="stat-label">Cars in Service</div>
                      <div className="stat-value">
                        {activeMechanic.carsInRepair.length}/{activeMechanic.maxCarsSimultaneously}
                      </div>
                    </div>
                  </div>
                  <div className="stat-card">
                    <span className="stat-icon">⚡</span>
                    <div className="stat-info">
                      <div className="stat-label">Repair Speed</div>
                      <div className="stat-value">
                        {activeMechanic.minutesPerDamagePoint} min/damage
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {activeMechanic.carsInRepair.length > 0 && (
                <details className="mechanic-details">
                  <summary>Cars Being Repaired ({activeMechanic.carsInRepair.length})</summary>
                  <div className="cars-in-repair">
                    {activeMechanic.carsInRepair.map(car => (
                      <div key={car.carId} className="repair-progress">
                        <h4>Car #{car.carId}</h4>
                        <div className="progress-bar">
                          <div 
                            className="progress" 
                            style={{
                              width: `${calculateProgress(car)}%`,
                            }}
                          />
                        </div>
                        <p>Damage: {car.currentDamage.toFixed(2)}%</p>
                        <p>Time Left: {timeLeft[car.carId] || 'Calculating...'}</p>
                        <button 
                          onClick={() => handleRemoveCar(car.carId)}
                          className="remove-car-btn"
                          disabled={removeTimers[car.carId] > 0}
                        >
                          {removeTimers[car.carId] > 0 
                            ? `Remove (${formatRemoveTimer(removeTimers[car.carId])})`
                            : 'Remove from Repair'
                          }
                        </button>
                      </div>
                    ))}
                  </div>
                </details>
              )}

              {/* Auto Repair Settings */}
              <div className="auto-repair-settings">
                <h4>Auto Repair Settings</h4>
                
                <div className="auto-repair-toggle">
                  <label className="toggle-switch">
                    <input
                      type="checkbox"
                      checked={activeMechanic.autoRepairEnabled}
                      onChange={() => handleAutoRepairToggle()}
                      disabled={!canUseAutoRepair(activeMechanic.type)}
                    />
                    <span className="toggle-slider"></span>
                  </label>
                  <span>Enable Auto Repair</span>
                </div>

                {canUseAutoRepair(activeMechanic.type) && (
                  <div className="rarity-toggles">
                    <h5>Auto Repair by Rarity</h5>
                    {Object.keys(activeMechanic.autoRepairRarities).map(rarity => (
                      <div key={rarity} className="rarity-toggle">
                        <label className="toggle-switch">
                          <input
                            type="checkbox"
                            checked={activeMechanic.autoRepairRarities[rarity]}
                            onChange={() => handleRarityToggle(rarity)}
                          />
                          <span className="toggle-slider"></span>
                        </label>
                        <span className={`rarity-label ${rarity}`}>{rarity}</span>
                      </div>
                    ))}
                  </div>
                )}

                {canUseStatusFilter(activeMechanic.type) && (
                  <div className="status-toggles">
                    <h5>Auto Repair by Status</h5>
                    {Object.keys(activeMechanic.autoRepairStatuses).map(status => (
                      <div key={status} className="status-toggle">
                        <label className="toggle-switch">
                          <input
                            type="checkbox"
                            checked={activeMechanic.autoRepairStatuses[status]}
                            onChange={() => handleStatusToggle(status)}
                          />
                          <span className="toggle-slider"></span>
                        </label>
                        <span className={`status-label ${status}`}>
                          {status.replace('_', ' ')}
                        </span>
                      </div>
                    ))}
                  </div>
                )}

                {!canUseAutoRepair(activeMechanic.type) && (
                  <div className="auto-repair-warning">
                    ⚠️ Novice mechanics cannot use auto repair features
                  </div>
                )}
                
                {activeMechanic.type === 'intermediate' && (
                  <div className="auto-repair-info">
                    ℹ️ Pro mechanics can only use rarity-based auto repair
                  </div>
                )}
              </div>
            </div>
          ) : (
            // Mechanics hiring section
            <div className="hire-mechanic">
              <div className="mechanic-header">
                <h3>
                  <span className="emoji">🔧</span> Available Mechanics
                </h3>
              </div>

              <div className="duration-selector">
                Contract Duration:
                <select 
                  value={selectedDuration} 
                  onChange={(e) => setSelectedDuration(Number(e.target.value))}
                >
                  <option value={1}>1 Day</option>
                  <option value={3}>3 Days</option>
                  <option value={7}>7 Days</option>
                  <option value={30}>30 Days</option>
                </select>
              </div>

              <div className="mechanic-options">
                {mechanicConfig && Object.entries(MECHANIC_TYPES).map(([type, info]) => {
                  const config = mechanicConfig[`${type}Mechanic`];
                  const totalCost = config.costPerDay * selectedDuration;

                  return (
                    <div key={type} className="mechanic-option">
                      <div className="mechanic-option-image">
                        <img 
                          src={info.image} 
                          alt={info.title} 
                          className="mechanic-avatar"
                        />
                      </div>

                      <div className="mechanic-option-header">
                        <h4>{info.title}</h4>
                        <p className="mechanic-description">{info.description}</p>
                      </div>

                      <div className="mechanic-details">
                        <div className="mechanic-features">
                          {info.features.map((feature, idx) => (
                            <span key={idx} className="feature">
                              <span>{feature.icon}</span> {feature.text}
                            </span>
                          ))}
                        </div>
                        <div className="mechanic-stats">
                          <p>
                            <span>Maximum Cars:</span>
                            <span>{config.maxCarsSimultaneously}</span>
                          </p>
                          <p>
                            <span>Repair Speed:</span>
                            <span>{config.minutesPerDamagePoint} minutes per damage point</span>
                          </p>
                          <p>
                            <span>Cost per Day:</span>
                            <span>{config.costPerDay} PXRS</span>
                          </p>
                        </div>
                        <p className="total-cost">
                          Total Cost: {totalCost.toFixed(8)} PXRS
                        </p>
                      </div>

                      <button
                        className="hire-button"
                        onClick={() => handleHireMechanic(type, selectedDuration)}
                        disabled={hiring}
                      >
                        {hiring ? 'Hiring...' : `Hire ${info.title}`}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {notification.show && (
            <Notification 
              message={notification.message}
              setShowNotification={(show) => setNotification(prev => ({ ...prev, show }))}
            />
          )}

          {showFireConfirm && (
            <div className="fire-confirm-modal">
              <div className="fire-confirm-content">
                <h3>🔧 Confirm Dismissal</h3>
                <p>
                  Are you sure you want to fire your {selectedMechanic && MECHANIC_TYPES[selectedMechanic.type].title}?
                  <br />
                  <small>This action cannot be undone.</small>
                </p>
                <div className="fire-confirm-buttons">
                  <button onClick={confirmFireMechanic}>Confirm Dismissal</button>
                  <button onClick={() => setShowFireConfirm(false)}>Cancel</button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MechanicSection;