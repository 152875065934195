import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import config from '../config';
import Cookies from 'js-cookie';
import './Report.css';

const Report = () => {
  const { userAccount } = useAuth();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });

  const showMessage = (text, type = 'error') => {
    setMessage({ text, type });
    setTimeout(() => setMessage({ text: '', type: '' }), 3000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!userAccount) {
      showMessage('You need to be logged in to submit a report');
      return;
    }

    if (!title.trim() || !description.trim()) {
      showMessage('Please fill in all fields');
      return;
    }

    setIsSubmitting(true);

    try {
      const authToken = Cookies.get('authToken');
      const csrfToken = Cookies.get('csrfToken');

      if (!authToken) {
        showMessage('Authentication token not found. Please log in again.');
        return;
      }

      const response = await fetch(`${config.apiBaseUrl}/api/reports/submit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
          'CSRF-Token': csrfToken
        },
        credentials: 'include',
        body: JSON.stringify({
          title,
          description
        })
      });

      const data = await response.json();

      if (data.success) {
        showMessage('Report submitted successfully', 'success');
        setTitle('');
        setDescription('');
      } else {
        showMessage(data.message || 'Error submitting report');
      }
    } catch (error) {
      showMessage('Error submitting report');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="report-container">
      <div className="report-content">
        <h2>Report a Problem</h2>
        <p className="report-info">
          Use this form to report bugs or issues you've encountered.
        </p>
        <p className="report-warning">
          ⚠️ Warning: This form is exclusively for reporting bugs and technical issues. Misuse of this form, including offensive language, spam, or targeted attacks against the team or project, will result in a permanent platform ban. Please maintain respect and contribute constructively to the game's development. Do not abuse the reporting system.
        </p>

        {message.text && (
          <div className={`message ${message.type}`}>
            {message.text}
          </div>
        )}

        <form onSubmit={handleSubmit} className="report-form">
          <div className="form-group">
            <label>
              Title
              <span className="char-count">
                {title.length}/100
              </span>
            </label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value.slice(0, 100))}
              placeholder="Enter a brief title for the issue"
              maxLength={100}
              required
            />
          </div>

          <div className="form-group">
            <label>
              Description
              <span className="char-count">
                {description.length}/1000
              </span>
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value.slice(0, 1000))}
              placeholder="Describe the issue in detail"
              maxLength={1000}
              rows={6}
              required
            />
          </div>

          <button 
            type="submit" 
            className="submit-button"
            disabled={isSubmitting || !userAccount}
          >
            {isSubmitting ? 'Submitting...' : 'Submit Report'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Report; 