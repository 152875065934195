// src/components/HomeGame.js

import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import {
  fetchGlobalValues,
  fetchMaps,
  fetchRacingCoins,
  fetchNFTs,
  fetchOngoingRaces,
  getRewards,
  fetchUser,
  checkCarInRace,
  checkItemInRace,
  fetchPXRSBalance,
  fetchFilteredNFTs,
  checkStake
} from '../utils/api';
import { startRace } from '../utils/raceActions';
import { formatTime } from '../utils/formatTime';
import io from 'socket.io-client';
import AdminPanel from '../components/adminpanel/AdminPanel';
import CarDisplay from '../components/CarDisplay';
import MapDisplay from '../components/MapDisplay';
import MapDetails from '../components/MapDetails';
import NFTSelector from '../components/NFTSelector';
import OngoingRaces from '../components/OngoingRaces';
import RewardsModal from '../components/RewardsModal';
import HeaderGame from '../components/HeaderGame';
import Notification from '../components/Notification';
import RaceStartAnimation from '../components/RaceStartAnimation';
import ItemSelection from '../components/ItemSelection';
import FooterGame from '../components/FooterGame';
import ProfileImageSelector from '../components/ProfileImageSelector';
import StoreModal from '../components/StoreModal';
import OficinaModal from '../components/OficinaModal'; // <-- Novo import
import GameSidebar from '../components/GameSidebar';
import CarAttributes from '../components/CarAttributes';

import '../styles/fonts.css';
import '../styles/global.css';
import '../styles/header.css';
import '../styles/sidebar.css';
import '../styles/carDisplay.css';
import '../styles/mapsSection.css';
import '../styles/attributes.css';
import '../styles/adminPanel.css';
import '../styles/nftSelector.css';
import '../styles/ongoingRaces.css';
import '../styles/rewardsModal.css';
import '../styles/startGame.css';
import '../styles/raceAnimation.css';
import '../styles/HomeGame.css';
import '../styles/ProfileImageSelector.css';

import config from '../config';
import defaultAvatar from '../assets/profile/default.png';

const socket = io(config.websocketUrl, { transports: ['websocket', 'polling', 'flashsocket'] });

const HomeGame = () => {
  const { userAccount, isAdmin, setRacingCoins, loading } = useAuth();
  const [showAdminPanel, setShowAdminPanel] = useState(false);
  const [maps, setMaps] = useState([]);
  const [currentMapIndex, setCurrentMapIndex] = useState(0);
  const [selectedCars, setSelectedCars] = useState([]);
  const [allEquippedItems, setAllEquippedItems] = useState([]);
  const [showSelectCar, setShowSelectCar] = useState(false);
  const [selectedMap, setSelectedMap] = useState(null);
  const [raceTime, setRaceTime] = useState(0);
  const [carUsage, setCarUsage] = useState(JSON.parse(localStorage.getItem('carUsage')) || {});
  const [showOngoingRaces, setShowOngoingRaces] = useState(false);
  const [ongoingRaces, setOngoingRaces] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [showRewards, setShowRewards] = useState(false);
  const [rewards, setRewards] = useState([]);
  const [racesCompleted, setRacesCompleted] = useState(0);
  const [raceLimit, setRaceLimit] = useState(500);
  const [resetTimer, setResetTimer] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [showRaceAnimation, setShowRaceAnimation] = useState(false);
  const [isStartButtonDisabled, setIsStartButtonDisabled] = useState(false);
  const [showItemSelection, setShowItemSelection] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isAttributesVisible, setIsAttributesVisible] = useState(true);

  const [pxrsBalance, setPxrsBalance] = useState(0);
  const [selectedProfileImage, setSelectedProfileImage] = useState(defaultAvatar);
  const [showProfileImageSelector, setShowProfileImageSelector] = useState(false);

  const [showBuildPanel, setShowBuildPanel] = useState(false);
  const [buildName, setBuildName] = useState('');
  const [availableItems, setAvailableItems] = useState([]);
  const [savedBuilds, setSavedBuilds] = useState([]);
  const [selectedCarIndexForAttributes, setSelectedCarIndexForAttributes] = useState(null);

  // Control store modal
  const [showStore, setShowStore] = useState(false);

  // Novo: Oficina (repair shop)
  const [showOficina, setShowOficina] = useState(false);

  const updateDailyRaceLimit = (newLimit) => {
    if (typeof newLimit === 'number' && !isNaN(newLimit)) {
      setRaceLimit(newLimit);
    }
  };

  const fetchPXRS = useCallback(async () => {
    if (userAccount) {
      try {
        const balance = await fetchPXRSBalance(userAccount);
        setPxrsBalance(balance);
      } catch (error) {
        console.error('Error fetching PXRS balance:', error);
      }
    }
  }, [userAccount]);

  useEffect(() => {
    fetchPXRS();
  }, [fetchPXRS]);

  useEffect(() => {
    const loadData = async () => {
      if (!userAccount) return;

      try {
        const globalValues = await fetchGlobalValues();
        setRaceLimit(globalValues.raceLimit || 500);
        setResetTimer(globalValues.resetTimer);

        const mapsData = await fetchMaps();
        setMaps(mapsData);

        const coins = await fetchRacingCoins(userAccount);
        setRacingCoins(coins);

        const user = await fetchUser(userAccount);
        setRacesCompleted(user.racesCompleted || 0);

        const userItems = await fetchFilteredNFTs(userAccount);
        setAvailableItems(userItems);
      } catch (error) {
        console.error('Error loading data:', error);
        setNotificationMessage('Error loading initial data.');
        setShowNotification(true);
      }
    };

    loadData();

    socket.on('map_expired', (mapId) => {
      setMaps((prevMaps) => prevMaps.filter((map) => map._id !== mapId));
    });

    socket.on('map_timer', ({ id, duration }) => {
      setMaps((prevMaps) => prevMaps.map((map) => (map._id === id ? { ...map, duration } : map)));
    });

    socket.on('map_deleted', (mapId) => {
      setMaps((prevMaps) => prevMaps.filter((map) => map._id !== mapId));
    });

    socket.on('reset_race_limits', ({ newResetTimer, raceLimit }) => {
      setResetTimer(newResetTimer);
      updateDailyRaceLimit(raceLimit);
      setRacesCompleted(0);
    });

    return () => {
      socket.off('map_expired');
      socket.off('map_timer');
      socket.off('map_deleted');
      socket.off('reset_race_limits');
    };
  }, [userAccount, setRacingCoins]);

  useEffect(() => {
    if (userAccount) {
      const storedImage = localStorage.getItem(`profileImage_${userAccount}`);
      setSelectedProfileImage(storedImage || defaultAvatar);
    } else {
      setSelectedProfileImage(defaultAvatar);
    }
  }, [userAccount]);

  const handleSelectCar = () => {
    setShowSelectCar(true);
  };

  const handleSelectNFT = (newCars) => {
    setSelectedCars((prevCars) => {
      const updatedCars = [...prevCars, ...newCars];
      if (updatedCars.length === 1) {
        setSelectedCarIndexForAttributes(0);
      } else {
        setSelectedCarIndexForAttributes(null);
      }
      return updatedCars;
    });

    setAllEquippedItems((prevItems) => [...prevItems, ...newCars.map(() => [null, null, null])]);
    setShowSelectCar(false);
  };

  const resetSelectedCars = async () => {
    setSelectedCars([]);
    setAllEquippedItems([]);
    setSelectedCarIndexForAttributes(null);

    if (userAccount) {
      const user = await fetchUser(userAccount);
      setRacesCompleted(user.racesCompleted || 0);
    }
  };

  const handleFetchOngoingRaces = () => {
    fetchOngoingRaces(userAccount)
      .then(setOngoingRaces)
      .catch((error) => {
        console.error('Error fetching ongoing races:', error);
        setNotificationMessage('Error loading ongoing races.');
        setShowNotification(true);
      });
  };

  const handleFetchRewards = () => {
    getRewards(userAccount)
      .then(setRewards)
      .catch((error) => {
        console.error('Error loading rewards:', error);
        setNotificationMessage('Error loading rewards.');
        setShowNotification(true);
      });
  };

  const handleEquipMultipleNFTs = (equips, carIndex) => {
    setAllEquippedItems((prev) => {
      const updated = [...prev];
      const carItems = [...updated[carIndex]];
      for (const { item, slotIndex } of equips) {
        carItems[slotIndex] = item;
      }
      updated[carIndex] = carItems;
      return updated;
    });
  };

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  const toggleAttributes = () => {
    setIsAttributesVisible((prevState) => !prevState);
  };

  const handleProfileImageClick = () => {
    setShowProfileImageSelector(true);
  };

  const handleSelectProfileImage = (image) => {
    setSelectedProfileImage(image);
    if (userAccount) {
      localStorage.setItem(`profileImage_${userAccount}`, image);
    }
    setShowProfileImageSelector(false);
  };

  const importAllImages = (r) => r.keys().map(r);
  const profileImages = importAllImages(require.context('../assets/profile', false, /\.(png|jpe?g|svg)$/));

  // Auto Select cars/items
  const handleAutoSelect = async () => {
    if (!userAccount) return;
    try {
      const userNfts = await fetchNFTs(userAccount);
      const staked = await checkStake();
      const cars = await Promise.all(
        userNfts.map(async (nft) => {
          const isInRaceData = await checkCarInRace(nft.asset_id);
          return {
            ...nft,
            inRace: isInRaceData.inRace || false,
            inStake: staked.includes(nft.asset_id),
          };
        })
      );

      const availableCars = cars.filter(
        (n) =>
          n.template &&
          n.template.immutable_data &&
          n.template.immutable_data['Car Rarity'] &&
          !n.inRace &&
          !n.inStake
      );

      const userItems = availableItems;
      const finalAvailableItems = [];
      for (let it of userItems) {
        const inRace = await checkItemInRace(it.asset_id);
        if (!inRace) {
          finalAvailableItems.push(it);
        }
      }

      const shuffledCars = availableCars.sort(() => 0.5 - Math.random());
      const selectedCarsAuto = shuffledCars.slice(0, 10);

      let equipped = [];
      let usedNFTIDs = new Set();

      for (let i = 0; i < selectedCarsAuto.length; i++) {
        const carItems = [];
        const shuffledItems = [...finalAvailableItems].sort(() => 0.5 - Math.random());
        const usedTemplatesInCar = new Set();
        for (let it of shuffledItems) {
          if (carItems.length < 3) {
            if (!usedNFTIDs.has(it.asset_id) && !usedTemplatesInCar.has(it.template.template_id)) {
              carItems.push(it);
              usedNFTIDs.add(it.asset_id);
              usedTemplatesInCar.add(it.template.template_id);
            }
          } else {
            break;
          }
        }
        while (carItems.length < 3) {
          carItems.push(null);
        }
        equipped.push(carItems);
      }

      setSelectedCars(selectedCarsAuto);
      setAllEquippedItems(equipped);
      if (selectedCarsAuto.length === 1) {
        setSelectedCarIndexForAttributes(0);
      } else {
        setSelectedCarIndexForAttributes(null);
      }
    } catch (err) {
      console.error(err);
      setNotificationMessage('Error on auto select.');
      setShowNotification(true);
    }
  };

  let carToShowAttributes = null;
  if (selectedCars.length === 1) {
    carToShowAttributes = selectedCars[0];
  } else if (selectedCars.length > 1 && selectedCarIndexForAttributes !== null && selectedCars[selectedCarIndexForAttributes]) {
    carToShowAttributes = selectedCars[selectedCarIndexForAttributes];
  }

  // Remove unavailable cars/items
  const checkAvailability = async (fromAuto = false) => {
    if (!userAccount) return;

    const updatedCars = [];
    const updatedItems = [];

    for (let i = 0; i < selectedCars.length; i++) {
      const car = selectedCars[i];
      if (!car || !car.asset_id) continue;
      const carStatus = await checkCarInRace(car.asset_id);
      if (!carStatus.inRace) {
        const carItems = [...allEquippedItems[i]];
        const filteredItems = [];
        for (let it of carItems) {
          if (!it) {
            filteredItems.push(null);
            continue;
          }
          const itemStatus = await checkItemInRace(it.asset_id);
          if (!itemStatus) {
            filteredItems.push(it);
          } else {
            filteredItems.push(null);
          }
        }
        updatedCars.push(car);
        updatedItems.push(filteredItems);
      }
    }

    setSelectedCars(updatedCars);
    setAllEquippedItems(updatedItems);

    if (!fromAuto) {
      setNotificationMessage('Availability checked. Unavailable cars/items removed.');
      setShowNotification(true);
    }
  };

  // Inicia as corridas em fila
  const runRaceQueue = async () => {
    if (selectedCars.length === 0 || !selectedMap) {
      setNotificationMessage('Please select car(s) and map before starting the race.');
      setShowNotification(true);
      setIsStartButtonDisabled(false);
      return;
    }

    let carsQueue = [...selectedCars];
    let itemsQueue = [...allEquippedItems];
    const totalCars = carsQueue.length;
    let raceStarted = false;
    let reachedLimit = false;
    let failedCars = [];
    let successfulIndices = new Set();

    for (let i = 0; i < carsQueue.length; i++) {
      if (racesCompleted >= raceLimit) {
        setNotificationMessage('Race limit reached. Please wait for the timer reset to race again.');
        setShowNotification(true);
        reachedLimit = true;
        break;
      }

      const currentCar = carsQueue[i];
      if (!currentCar || !currentCar.asset_id) {
        continue;
      }

      const equippedItemsForRace = Array.isArray(itemsQueue[i]) ? itemsQueue[i] : [];

      try {
        await startRace(
          currentCar,
          selectedMap,
          userAccount,
          carUsage,
          setCarUsage,
          setRaceTime,
          setErrorMessage,
          setRacesCompleted,
          equippedItemsForRace
        );

        raceStarted = true;
        successfulIndices.add(i);
        setNotificationMessage(`Race started for car ${currentCar.asset_id}!`);
        setShowNotification(true);

        if (userAccount) {
          const user = await fetchUser(userAccount);
          setRacesCompleted(user.racesCompleted || 0);
        }
      } catch (error) {
        console.error('Error starting race:', error);
        const errorMsg = error.message || 'Unknown error starting the race.';
        failedCars.push({ car: currentCar, error: errorMsg, index: i });
      }
    }

    // Remove only successful races from the queue
    const updatedCars = carsQueue.filter((_, index) => !successfulIndices.has(index));
    const updatedItems = itemsQueue.filter((_, index) => !successfulIndices.has(index));
    
    setSelectedCars(updatedCars);
    setAllEquippedItems(updatedItems);
    setIsStartButtonDisabled(false);

    if (!reachedLimit) {
      if (failedCars.length > 0) {
        const failedMessage = failedCars.map(f => `Car ${f.car.asset_id}: ${f.error}`).join('\n');
        setNotificationMessage(`Some races failed:\n${failedMessage}\nFailed cars will remain in their slots for retry.`);
      } else if (raceStarted && totalCars > 1) {
        setNotificationMessage('All queued races started successfully!');
      }
      setShowNotification(true);
    }
  };

  const handleStartRace = () => {
    const noCarsSelected = selectedCars.length === 0;
    const noMapSelected = !selectedMap;

    if (noCarsSelected && noMapSelected) {
      setNotificationMessage('Please select at least one car and a map before starting the race.');
      setShowNotification(true);
      return;
    } else if (noCarsSelected) {
      setNotificationMessage('Please select at least one car before starting the race.');
      setShowNotification(true);
      return;
    } else if (noMapSelected) {
      setNotificationMessage('Please select a map before starting the race.');
      setShowNotification(true);
      return;
    }

    setIsStartButtonDisabled(true);
    setShowRaceAnimation(true);
  };

  const handleAnimationEnd = async () => {
    setShowRaceAnimation(false);
    await runRaceQueue();
  };

  if (loading) {
    return (
      <div className="loading-screen">
        <div className="loading-text">Loading...</div>
      </div>
    );
  }

  return (
    <div className="home-game">
      <HeaderGame
        setShowAdminPanel={setShowAdminPanel}
        isAdmin={isAdmin}
        updateDailyRaceLimit={updateDailyRaceLimit}
        onClickProfileImage={handleProfileImageClick}
        selectedProfileImage={selectedProfileImage}
        pxrsBalance={pxrsBalance.toFixed(2)}
        fetchPXRS={fetchPXRS}
        setShowRewards={setShowRewards}
        showRewards={showRewards}
        rewards={rewards}
        setNotificationMessage={setNotificationMessage}
        setShowNotification={setShowNotification}
        setShowStore={setShowStore}
      />

      <div className="main-content">
        <div className="game-area">
          <GameSidebar 
            setShowRewards={setShowRewards}
            handleFetchRewards={handleFetchRewards}
            setShowOngoingRaces={setShowOngoingRaces}
            handleFetchOngoingRaces={handleFetchOngoingRaces}
            setShowOficina={setShowOficina}
          />
          
          <div className="car-display-container">
            <CarDisplay
              userAccount={userAccount}
              selectedCars={selectedCars}
              setSelectedCars={setSelectedCars}
              allEquippedItems={allEquippedItems}
              setAllEquippedItems={setAllEquippedItems}
              setShowItemSelection={setShowItemSelection}
              setNotificationMessage={setNotificationMessage}
              setShowNotification={setShowNotification}
              handleSelectCar={handleSelectCar}
              handleOpenBuildPanel={() => setShowBuildPanel(!showBuildPanel)}
              handleAutoSelect={handleAutoSelect}
              selectedCarIndexForAttributes={selectedCarIndexForAttributes}
              setSelectedCarIndexForAttributes={setSelectedCarIndexForAttributes}
              showBuildPanel={showBuildPanel}
              setShowBuildPanel={setShowBuildPanel}
              buildName={buildName}
              setBuildName={setBuildName}
              savedBuilds={savedBuilds}
              setSavedBuilds={setSavedBuilds}
              checkAvailability={checkAvailability}
              clearAllPresets={resetSelectedCars}
              onCarSelect={(index) => {
                setSelectedCarIndexForAttributes(index);
              }}
            />
            
            <button className="toggle-attributes-button" onClick={() => setIsAttributesVisible(!isAttributesVisible)}>
              {isAttributesVisible ? 'Hide Attributes' : 'Show Attributes'}
            </button>
            
            <CarAttributes 
              carToShowAttributes={selectedCarIndexForAttributes !== null ? selectedCars[selectedCarIndexForAttributes] : null}
              selectedCars={selectedCars}
              isVisible={isAttributesVisible}
            />
          </div>
          
          <MapDisplay
            maps={maps}
            setMaps={setMaps}
            selectedMap={selectedMap}
            handleSelectMap={setSelectedMap}
            currentMapIndex={currentMapIndex}
            setCurrentMapIndex={setCurrentMapIndex}
          />

          {selectedMap && <MapDetails selectedMap={selectedMap} />}

          <button
            className="start-button"
            onClick={handleStartRace}
            disabled={isStartButtonDisabled}
          >
            START GAME
          </button>

          <FooterGame />
        </div>
      </div>

      {showAdminPanel && (
        <AdminPanel setShowAdminPanel={setShowAdminPanel} maps={maps} setMaps={setMaps} />
      )}

      {showSelectCar && (
        <NFTSelector
          selectedCars={selectedCars}
          onSelectNFT={handleSelectNFT}
          setShowSelectCar={setShowSelectCar}
          setNotificationMessage={setNotificationMessage}
          setShowNotification={setShowNotification}
        />
      )}

      {showOngoingRaces && (
        <OngoingRaces
          showOngoingRaces={showOngoingRaces}
          setShowOngoingRaces={setShowOngoingRaces}
          userAccount={userAccount}
          setNotificationMessage={setNotificationMessage}
          setShowNotification={setShowNotification}
        />
      )}

      {showOficina && (
        <OficinaModal
          showOficina={showOficina}
          setShowOficina={setShowOficina}
          userAccount={userAccount}
        />
      )}

      {showRewards && (
        <RewardsModal
          showRewards={showRewards}
          rewards={rewards}
          setShowRewards={setShowRewards}
          userAccount={userAccount}
          setNotificationMessage={setNotificationMessage}
          setShowNotification={setShowNotification}
          fetchPXRS={fetchPXRS}
        />
      )}

      {showNotification && (
        <Notification message={notificationMessage} setShowNotification={setShowNotification} />
      )}

      {showRaceAnimation && (
        <RaceStartAnimation
          onAnimationEnd={handleAnimationEnd}
          key={showRaceAnimation ? 'show' : 'hide'}
        />
      )}

      {showItemSelection && (
        <ItemSelection
          setShowNFTSelector={setShowItemSelection}
          handleEquipMultipleNFTs={handleEquipMultipleNFTs}
          maxItems={3}
          allEquippedItemsGlobal={allEquippedItems}
          currentCarIndex={showItemSelection.carIndex}
          currentItemIndex={showItemSelection.itemIndex}
          setNotificationMessage={setNotificationMessage}
          setShowNotification={setShowNotification}
        />
      )}

      {showProfileImageSelector && (
        <ProfileImageSelector
          images={profileImages}
          onSelectImage={handleSelectProfileImage}
          onClose={() => setShowProfileImageSelector(false)}
          userAccount={userAccount}
        />
      )}

      <StoreModal show={showStore} onClose={() => setShowStore(false)} />
    </div>
  );
};

export default HomeGame;
