// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Layout from './pages/Layout';
import Home from './pages/Home';
import Drops from './pages/Drops';
import HomeGame from './phaser/HomeGame';
import Stake from './pages/Stake';
// import OpenPack from './pages/OpenPack';
import About from './pages/About'; // Importar o componente About
import Cassino from './pages/cassino';
import OficinaModal from './components/OficinaModal';
import Notification from './components/Notification';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import ErrorBoundary from './ErrorBoundary';
import Report from './pages/Report';

const queryClient = new QueryClient();

const App = () => {
  const [showOficina, setShowOficina] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <Router>
            <Routes>
              {/* Rotas com Layout (Header e Footer) */}
              <Route element={<Layout />}>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/drops" element={<Drops />} />
                <Route path="/stake" element={<Stake />} />
               {/*<Route path="/open-pack" element={<OpenPack />} />*/}
                <Route path="/about" element={<About />} /> {/* Nova rota para About */}
                {/*<Route path="/cassino" element={<Cassino />} /> {/* Nova rota para Cassino */}
                <Route path="/report" element={<Report />} />
              </Route>

              {/* Rotas sem Layout */}
              <Route path="/homegame" element={<HomeGame />} />
            </Routes>
            <OficinaModal 
              showOficina={showOficina}
              setShowOficina={setShowOficina}
              setNotificationMessage={setNotificationMessage}
              setShowNotification={setShowNotification}
            />
            {showNotification && (
              <Notification 
                message={notificationMessage} 
                onClose={() => setShowNotification(false)} 
              />
            )}
          </Router>
          <ReactQueryDevtools initialIsOpen={false} />
        </AuthProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
