import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import io from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import RankingModal from './RankingModal';
import { fetchGlobalValues, fetchRanking, fetchUser, fetchUserRank } from '../utils/api';
import '../styles/header.css';
import logo from '../assets/logo.png';
import RewardsModal from './RewardsModal';
import defaultAvatar from '../assets/profile/default.png';
import StoreModal from './StoreModal';

const socket = io(config.websocketUrl, {
  transports: ['websocket', 'polling', 'flashsocket'],
});

const HeaderGame = ({
  setShowAdminPanel,
  updateDailyRaceLimit,
  onClickProfileImage,
  selectedProfileImage,
  rewards,
  fetchPXRS,
  pxrsBalance,
  showRewards,
  setShowRewards,
  setNotificationMessage,
  setShowNotification,
}) => {
  const { userAccount, isAdmin, loading } = useAuth();
  const [dailyRaceLimit, setDailyRaceLimit] = useState(0);
  const [racesCompleted, setRacesCompleted] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const [adminPassword, setAdminPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showRanking, setShowRanking] = useState(false);
  const [showStore, setShowStore] = useState(false);
  const [error, setError] = useState('');
  const [level, setLevel] = useState(0);
  const [xp, setXp] = useState(0);
  const [ranking, setRanking] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (userAccount) {
      fetchPXRS();
    }
  }, [userAccount, fetchPXRS]);

  const fetchUpdatedValues = async () => {
    try {
      if (userAccount) {
        const globalValues = await fetchGlobalValues();
        const updatedUser = await fetchUser(userAccount);
        const totalLimit = (globalValues.raceLimit || 0) + (updatedUser.bonusRaceLimit || 0);

        setDailyRaceLimit(totalLimit);
        setRacesCompleted(updatedUser.racesCompleted || 0);

        const resetTime = new Date(globalValues.resetTimer);
        updateRemainingTime(resetTime);

        updateDailyRaceLimit(totalLimit);

        setLevel(updatedUser.level || 0);
        setXp(updatedUser.xp || 0);
      }
    } catch (error) {
      console.error('Error updating values:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!userAccount) return;
      try {
        const globalValues = await fetchGlobalValues();
        const user = await fetchUser(userAccount);
        const totalLimit = (globalValues.raceLimit || 0) + (user.bonusRaceLimit || 0);

        setDailyRaceLimit(totalLimit);
        setRacesCompleted(user.racesCompleted || 0);

        setLevel(user.level || 0);
        setXp(user.xp || 0);

        const resetTime = new Date(globalValues.resetTimer);
        updateRemainingTime(resetTime);

        updateDailyRaceLimit(totalLimit);

        updateUserRanking();
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    const updateUserRanking = async () => {
      try {
        if (userAccount) {
          const rankingData = await fetchRanking();
          const userRanking = rankingData.find((u) => u.walletAddress === userAccount);
          if (userRanking) {
            setRanking(rankingData.indexOf(userRanking) + 1);
          } else {
            const userRankResponse = await fetchUserRank(userAccount);
            setRanking(userRankResponse.rank || 'N/A');
          }
        }
      } catch (error) {
        console.error('Error fetching ranking:', error);
      }
    };

    fetchData();

    socket.on('levelUpdated', (newLevel) => setLevel(newLevel));
    socket.on('xpUpdated', (newXp) => setXp(newXp));
    socket.on('rankingUpdated', updateUserRanking);
    socket.on('reset_race_limits', async ({ newResetTimer }) => {
      const resetTime = new Date(newResetTimer);
      updateRemainingTime(resetTime);

      try {
        if (userAccount) {
          const updatedUser = await fetchUser(userAccount);
          setRacesCompleted(updatedUser.racesCompleted || 0);

          const updatedGlobalValues = await fetchGlobalValues();
          const totalLimit = (updatedGlobalValues.raceLimit || 0) + (updatedUser.bonusRaceLimit || 0);

          setDailyRaceLimit(totalLimit);
          updateDailyRaceLimit(totalLimit);
        }
      } catch (error) {
        console.error('Error fetching user data on reset_race_limits:', error);
      }
    });

    return () => {
      socket.off('levelUpdated');
      socket.off('xpUpdated');
      socket.off('rankingUpdated', updateUserRanking);
      socket.off('reset_race_limits');
    };
  }, [updateDailyRaceLimit, userAccount]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 1) {
          fetchUpdatedValues();
          return 0;
        }
        return Math.max(0, prevTime - 1);
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  const updateRemainingTime = (resetTime) => {
    const now = new Date();
    const timeDiff = (resetTime - now) / 1000;
    setRemainingTime(Math.max(0, timeDiff));
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const handleAdminLogin = async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/admin/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userAccount, adminPassword }),
      });
      const data = await response.json();
      if (data.success) {
        setShowAdminPanel(true);
        setShowModal(false);
      } else {
        setError('Invalid password');
      }
    } catch (error) {
      console.error('Error logging in as admin:', error);
      setError('An error occurred during login');
    }
  };

  const openModal = () => setShowModal(true);
  const closeModal = () => { setShowModal(false); setError(''); };
  const toggleRanking = () => setShowRanking(!showRanking);
  const navigateHome = () => navigate('/home');

  if (loading) return <div>Loading Auth...</div>;

  // Calculation for XP bar with reset when reaching next level
  const computedLevel = Math.floor(0.1 * Math.sqrt(xp));
  const currentLevel = computedLevel;
  if (computedLevel !== level) {
    setLevel(computedLevel);
  }
  const xpForCurrentLevel = Math.pow(currentLevel / 0.1, 2);
  const xpForNextLevel = Math.pow((currentLevel + 1) / 0.1, 2);
  const progressPercent = xpForNextLevel 
    ? ((xp - xpForCurrentLevel) / (xpForNextLevel - xpForCurrentLevel)) * 100 
    : 0;
  const remainingPercent = 100 - progressPercent;

  return (
    <div className="header-container">
      <div className="header-top">
        <div className="header-logo-container">
          <img src={logo} alt="Logo" className="header-logo" />
        </div>
        <div className="header-buttons-container">
          {isAdmin && (
            <button className="button admin-panel-button" onClick={openModal}>ADM</button>
          )}
          <button className="button ranking-button" onClick={toggleRanking}>RANKING</button>
          <button className="button back-button" onClick={navigateHome}>BACK</button>
        </div>
      </div>

      <div className="img-wallet-user">
        <img
          src={selectedProfileImage || defaultAvatar}
          alt="User Avatar"
          className="user-avatar"
          onClick={userAccount ? onClickProfileImage : null}
          title={userAccount ? '' : 'Hey, play now! Log in to start a race!'}
          style={{ cursor: userAccount ? 'pointer' : 'not-allowed' }}
        />
      </div>

      <div className="header-main">
        <div className="wallet-info info-card">
          <div className="body">
            <p className="wallet-address">{userAccount || 'N/A'}</p>
            <p className="wallet-balance">PXRS: {parseFloat(pxrsBalance).toFixed(2)}</p>
          </div>
        </div>
        <div className="race-info info-card">
          <div className="race-info-header">
            <p>
              <span>Races:</span>
              <span>{dailyRaceLimit - racesCompleted}/{dailyRaceLimit}</span>
              <button 
                className="add-races-button" 
                onClick={() => setShowStore(true)}
                title="Buy more races"
              >
                +
              </button>
            </p>
          </div>
          <div className="progress-bar">
            <div
              className="progress"
              style={{ width: `${((dailyRaceLimit - racesCompleted) / dailyRaceLimit) * 100}%` }}
            ></div>
          </div>
          <p className="time">Reset: {formatTime(remainingTime)}</p>
        </div>
      </div>

      <div className="header-bottom">
        <div className="level-user">
          <span>LEVEL {level}</span>
        </div>
        <div className="xp-bar-card info-card">
          <div className="xp-bar-container">
            <div className="xp-bar">
              <div className="xp-progress" style={{ width: `${progressPercent}%` }}></div>
              <span className="xp-label">
                {Math.floor(xp).toLocaleString()}/{Math.floor(xpForNextLevel).toLocaleString()} XP
              </span>
            </div>
            <div className="xp-percent">
              {remainingPercent.toFixed(0)}% to next level
            </div>
          </div>
        </div>
        <div className="ranking-user">
          <span>RANK: {ranking !== null && ranking !== 'N/A' ? `${ranking}` : 'N/A'}</span>
        </div>
      </div>

      {showModal && (
        <div className="admin-modal">
          <div className="admin-modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <input
              type="password"
              className="admin-input"
              placeholder="Admin Password"
              value={adminPassword}
              onChange={(e) => setAdminPassword(e.target.value)}
            />
            <button className="admin-login-button" onClick={handleAdminLogin}>
              Login
            </button>
            {error && <p className="error">{error}</p>}
          </div>
        </div>
      )}
      <RankingModal show={showRanking} onClose={toggleRanking} />
      {showRewards && (
        <RewardsModal
          showRewards={showRewards}
          setShowRewards={setShowRewards}
          userAccount={userAccount}
          rewards={rewards}
          fetchPXRS={fetchPXRS}
          setNotificationMessage={setNotificationMessage}
          setShowNotification={setShowNotification}
        />
      )}
      {showStore && <StoreModal show={showStore} onClose={() => setShowStore(false)} />}
    </div>
  );
};

export default HeaderGame;
