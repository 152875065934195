import React, { useState, useEffect } from 'react';
import { fetchMechanicConfig, updateMechanicConfig } from '../../utils/api';

const MechanicConfigSettings = () => {
  const [mechanicConfig, setMechanicConfig] = useState({
    noviceMechanic: {
      repairTimeForMaxDamage: 60,
      maxCarsSimultaneously: 3,
      costPerDay: 10
    },
    expertMechanic: {
      repairTimeForMaxDamage: 30,
      maxCarsSimultaneously: 5,
      costPerDay: 20
    }
  });

  useEffect(() => {
    const fetchConfigs = async () => {
      try {
        const data = await fetchMechanicConfig();
        if (data.mechanicConfig) setMechanicConfig(data.mechanicConfig);
      } catch (error) {
        console.error('Erro ao carregar configurações:', error);
      }
    };
    fetchConfigs();
  }, []);

  const handleMechanicConfigChange = (mechanicType, field, value) => {
    setMechanicConfig(prev => ({
      ...prev,
      [mechanicType]: {
        ...prev[mechanicType],
        [field]: Number(value)
      }
    }));
  };

  const saveConfigs = async () => {
    try {
      await updateMechanicConfig(mechanicConfig);
      alert('Configurações salvas com sucesso!');
    } catch (error) {
      console.error('Erro ao salvar configurações:', error);
      alert('Erro ao salvar configurações');
    }
  };

  return (
    <div className="mechanic-config-settings">
      <h3>Configurações dos Mecânicos</h3>
      
      {Object.entries(mechanicConfig).map(([mechanicType, config]) => (
        <div key={mechanicType} className="mechanic-type-config">
          <h4>{mechanicType === 'noviceMechanic' ? 'Mecânico Novato' : 'Mecânico Especialista'}</h4>
          
          <div className="config-field">
            <label>Tempo de Reparo para Dano Máximo (minutos):</label>
            <input
              type="number"
              value={config.repairTimeForMaxDamage}
              onChange={(e) => handleMechanicConfigChange(mechanicType, 'repairTimeForMaxDamage', e.target.value)}
            />
          </div>

          <div className="config-field">
            <label>Máximo de Carros Simultâneos:</label>
            <input
              type="number"
              value={config.maxCarsSimultaneously}
              onChange={(e) => handleMechanicConfigChange(mechanicType, 'maxCarsSimultaneously', e.target.value)}
            />
          </div>

          <div className="config-field">
            <label>Custo por Dia (PXRS):</label>
            <input
              type="number"
              value={config.costPerDay}
              onChange={(e) => handleMechanicConfigChange(mechanicType, 'costPerDay', e.target.value)}
            />
          </div>
        </div>
      ))}

      <button onClick={saveConfigs} className="save-config-button">
        Salvar Configurações
      </button>
    </div>
  );
};

export default MechanicConfigSettings; 