import React, { useEffect, useState } from 'react';
import './Drops.css';

const Drops = () => {
  const [drops, setDrops] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDrops = async () => {
      try {
        const response = await fetch('https://api.neftyblocks.me/drops?collection_name=pixelracesgo');
        if (!response.ok) {
          throw new Error('Failed to fetch drops');
        }
        const data = await response.json();
        console.log('Fetched Drops:', data); // Debug: Log the fetched data
        setDrops(data);
      } catch (error) {
        console.error('Error fetching drops:', error); // Debug: Log the error
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDrops();
  }, []);

  if (loading) {
    return <div className="loading">Loading drops...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="market-container">
      {drops.length > 0 ? (
        <>
          <h2 className="drops-title">Drops</h2>
          <div className="drops-grid">
            {drops.map((drop) => (
              <div key={drop.id} className="drop-card">
                {drop.assets && drop.assets[0] && drop.assets[0].image ? ( // Checking if image exists
                  <img src={drop.assets[0].image} alt={drop.name} className="drop-image" />
                ) : (
                  <div className="no-image">No Image Available</div> // Fallback if image is missing
                )}
                <div className="drop-info">
                  <h3 className="drop-title">{drop.name}</h3>
                  <a href={drop.drop_url} target="_blank" rel="noopener noreferrer" className="buy-button">Buy Now</a>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div>No drops found for this collection.</div>
      )}
    </div>
  );
};

export default Drops;
